import {
    Avatar,
    Card,
    CardBody,
    Rating,
    Typography,
  } from "@material-tailwind/react";
  
  export function CardReview({ name, feedback, date, img, rating }) {
    return (
      <Card shadow={false}>
        <CardBody className="pt-0">
          <Rating value={rating} color="amber" readonly className="mb-6 text-amber-500" />
          <Typography className="text-base font-normal !text-gray-500">
            {feedback}
          </Typography>
          <div className="mt-6 flex items-center gap-4">
            <div>
              <Typography
                variant="h6"
                color="blue-gray"
                className="font-medium"
              >
                {name}
              </Typography>
              <Typography className="text-base font-normal !text-gray-500">
                {date}
              </Typography>
            </div>
          </div>
          <hr className="mt-6 mb-2 h-[1px] w-1/2 bg-gray-200 " />
        </CardBody>
      </Card>
    );
  }