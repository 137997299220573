import React from 'react'
import Layout from '../../layout/Layout'

const Garantias = () => {
  return (
    <Layout>
        <div className="container mx-auto bg-white p-5 shadow my-10 flex flex-col justify-start items-start gap-5">
            <h1 className='font-medio text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight'>Garantías</h1>
            <h2 className='text-base font-negro text-azul'>Garantías del producto</h2>
            <p className='text-sm pl-5'>Los productos que ofrecemos contienen en su empaque una garantía emitida por los fabricantes o importadores, consúltala a detalle para que puedas hacerla efectiva. Si tienes algún problema, contáctanos al (834) 318 2700 Ext 125 y nosotros te ayudaremos.</p>
            <h2 className='text-base font-negro text-azul'>¿Cuántos años de garantia tiene mi producto?</h2>
            <p className='text-sm pl-5'>Todos los productos tienen hasta un año de garantía con los fabricantes, Si tienes algún problema, contáctanos al (834) 318 2700 Ext 125 y nosotros te ayudaremos.</p>
            <h2 className='text-base font-negro text-azul'>Ya hice mi reporte, aún no me dan solución ¿Que hago?</h2>
            <p className='text-sm pl-5'>Comunícate con nosotros vía Facebook y te ayudaremos a buscar una solución rápida.</p>
        </div>
    </Layout>
  )
}

export default Garantias