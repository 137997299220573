import React, { useState } from 'react'
import Layout from '../../layout/Layout'
import { FaEnvelope } from "react-icons/fa";

import {
    Accordion,
    AccordionHeader,
    AccordionBody,
  } from "@material-tailwind/react";

const paginawebfaq = [
    {
        pregunta: '¿Mis datos están seguros en la página web?',
        respuesta: [
            'Si, toda la información personal es completamente confidencial, tu información de pago está protegida con los más estrictos estándares de seguridad.'
        ]
    },
    {
        pregunta: '¿Me tengo que registrar para realizar una compra?',
        respuesta: [
            'No, puedes realizar tu compra como invitado al momento de realizar el proceso de compra.'
        ]
    },
    {
        pregunta: '¿Los productos tienen descuento?',
        respuesta: [
            'Si, todos los productos cuentan con un descuento especial dependiendo del mes corriente, pero visita nuestras redes sociales, ahí estaremos publicando las promociones especiales adicionales. Todos nuestros precios están en moneda nacional (pesos mexicanos).'
        ]
    },
    {
        pregunta: 'Ya hice mi compra, ¿Ahora qué sigue?',
        respuesta: [
            'Uno de nuestros asesores se comunicará contigo en un horario de 8:00 a 20:00 de lunes a sábado, para apoyarte con la fecha tentativa de reparto, si realizaste tu compra el domingo, no te preocupes, el lunes a primera hora nos estaremos comunicando contigo.'
        ]
        },
    {
        pregunta: '¿Puedo facturar mi compra en linea?',
        respuesta: [
            'Si, al momento de realizar el proceso de compra, en el paso 2 podrás solicitar tu factura, llenando algunos datos básicos.',
            'Lo único que necesitas es lo siguiente:',
            '- RFC (Registro Federal de Contribuyentes)',
            '- Nombre (persona física) / Razón Social (persona moral)',
            '- Domicilio Fiscal',
            '- Correo Electrónico',
            'La factura será enviada a tu correo electrónico, en caso de no recibirla favor de comunicarte via facebook.'
        ]
    }
]

const creditofaq = [
    {
        pregunta: '¿Cuales son los requisitos para obtener crédito?',
        respuesta: [
            "- INE",
            "- Comprobante de domicilio",
            "- Comprobante de ingresos",
            "- 3 Referencias personales",
            "- Un aval (con un crédito activo con nosotros o la misma papelería mencionada)",
            "Los solicitantes a crédito deberán ser de nacionalidad mexicana."
        ]
    },
    {
        pregunta: "¿Dónde puedo realizar los pagos de mi mensualidad?",
        respuesta: [
            "Se puede realizar directamente en cualquiera de nuestras sucursales, deposito en banco u OXXO y transferencia bancaria.",

            "Si realizas tu depósito a través de OXXO o transferencia bancaria, favor de enviar al numero de Whatsapp (834) 354 5696 lo siguiente:",

            "- Foto del comprobante",
            "- Nombre del titular de la cuenta",
            "o",
            <div className='flex flex-col lg:flex-row justify-between items-center lg:items-start gap-5 w-full max-w-4xl border-2 border-azul p-4 mx-auto'>
              <div className='flex flex-col justify-start items-center gap-2 flex-1'>
                <h3 className='text-azul font-negro text-base'>BANCO</h3>
                <img src="https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/Banco-img/Logo-ban4.png" alt="Logo Banorte" className='w-40' />
              </div>
              <div className='flex flex-col justify-start items-center gap-2 flex-1'>
                <h3 className='text-azul font-negro text-base'>CUENTA</h3>
                <p className='pt-3'>0021298239</p>
              </div>
              <div className='flex flex-col justify-start items-center gap-2 flex-1'>
                <h3 className='text-azul font-negro text-base'>CLABE</h3>
                <p className='pt-3'>072810000212982396</p>
              </div>
            </div>,
            "Favor de reportar el pago al número de WhatsApp (834) 354 5696",

            "Si no cuentas con número de Whatsapp, favor de comunicarse al (834) 318 2700 Ext-160 para mayor información.",
        ]
    },
    {
        pregunta: "¿Dónde puedo conocer el estatus de mi cuenta?",
        respuesta: [
            "Para conocer el estatus de tu cuenta puedes comunicarte al número (834) 318 2700 Ext-110 o contactanos a través de nuestras redes sociales y nosotros te ayudaremos."
        ]
    },
    {
        pregunta: "¿Qué referencias bancarias puedo dar al solicitar mi crédito en Mueblería Villarreal?",
        respuesta: [
            "Solicitamos 2 meses en comprobantes de pagos de la empresa donde laboras o un estado de cuenta donde se refleje una actividad económica constante."
        ]
    },
    {
        pregunta: "¿Dónde actualizo mis datos personales como dirección, email y celular?",
        respuesta: [
            "Para actualizar toda tu información personal puedes visitarnos en cualquiera de nuestras sucursales o comunicate al (834) 318 2700 Ext-110."
        ]
    }
]

const garantiafaq = [
    {
        pregunta: "Garantía del producto",
        respuesta: [
            "Los productos que ofrecemos contienen en su empaque una garantía emitida por los fabricantes o importadores, consúltala a detalle para que puedas hacerla efectiva. Si tienes algún problema, contáctanos al (834) 318 2700 Ext 125 y nosotros te ayudaremos."
        ]
    },
    {
        pregunta: "¿Cuántos años de garantía tiene mi producto?",
        respuesta: [
            "Todos los productos tienen hasta un año de garantía con los fabricantes, Si tienes algún problema, contáctanos al (834) 318 2700 Ext 125 y nosotros te ayudaremos."
        ]
    },
    {
        pregunta: "Ya hice mi reporte, aún no me dan solucion ¿Que hago?",
        respuesta: [
            `Comunícate con nosotros vía <a href="https://m.me/VillarrealMx">Facebook</a> y te ayudaremos a buscar una solución rápida.`
        ]
    }
]

const tiendafaq = [
    {
        pregunta: "¿Dónde puedo encontrarlos?",
        respuesta: [
            "Muebleria Villarreal Caballero S.A de C.V solo cuenta con sucursales en Cd Victoria y Matamoros Tamaulipas, no contamos con sucursales fuera del estado."
        ]
    },
    {
        pregunta: "¿Tienen envíos a domicilio?",
        respuesta: [
            "Muebleria Villarreal Caballero S.A de C.V cuenta con envío gratuito a Cd Victoria, Matamoros y Soto la Marina Tamaulipas, entregas foráneas tienen un costo mínimo de flete, fuera de Tamaulipas se realiza una cotización especial de envío."
        ]
    },
    {
        pregunta: "¿Qué horarios tienen?",
        respuesta: [
            `Puedes encontrar el horario de nuestras sucursales <a href="https://www.muebleria-villarreal.com/Empresa/Sucursales">aquí</a>.`,
            "El horario de atención en nuestro sitio web y redes sociales es de 9:00 a 20:00 de lunes a sábado, estamos para ayudarte con todas tus dudas.",
        ]
    },
    {
        pregunta: "¿Cómo obtener la factura de compra en tienda?",
        respuesta: [
            "Al momento de realizar tu compra en cualquiera de nuestras sucursales, recuerda solicitarla en caja al momento de realizar tu pago."
        ]
    }
];

const enviosfaq = [
    {
        pregunta: "¿Cuánto tiempo tarda en llegar mi producto?",
        respuesta: [
            "Para las entregas en Cd Victoria y Matamoros Tamaulipas las entregas son aproximadamente 4 días hábiles, puede variar dependiendo de la demanda de entrega.",
            "Si la compra es foránea, uno de nuestros asesores comerciales se comunicará contigo para darte una fecha tentativa de entrega."
        ]
    }
];

function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
    );
  }

const PreguntasFrecuentes = () => {
    
    const [open, setOpen] = useState([false, false, false, false, false]);

    const handleOpen = (index) => {
      setOpen((prevOpen) => {
        const newOpen = [...prevOpen];
        newOpen[index] = !newOpen[index];
        return newOpen;
      });
    };

  return (
    <Layout>
        <div className="container mx-auto py-10 lg:py-20 px-4 flex flex-col lg:flex-row justify-between items-start gap-0">
            <aside className='flex flex-col justify-start items-start gap-3 w-full lg:w-3/12 bg-white shadow rounded p-5'>
                <h1 className='font-medio text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight'>Preguntas frecuentes</h1>
                <ul className="pl-5">
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#paginawebfaq"> Página Web </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#creditofaq"> Créditos </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#garantiafaq"> Garantías </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#tiendafaq"> Tiendas </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#enviosfaq"> Envíos </a> </li>
                </ul>
            </aside>
            <div className='flex flex-col justify-start items-start gap-3 w-full lg:w-9/12 py-10 lg:py-5 px-4 lg:px-10 pt-10'>
                <h1 id='paginawebfaq' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5'>Página Web</h1>
                {
                    paginawebfaq.map((faq, index) => (
                        <Accordion open={open[index]} key={index} icon={<Icon id={index} open={open[index]} />}>
                            <AccordionHeader className='text-sm text-azul hover:bg-gray-100' onClick={() => handleOpen(index)}>{faq.pregunta}</AccordionHeader>
                            <AccordionBody>
                                {
                                    faq.respuesta.map((res, index) => (
                                        <p className='pb-3' key={index}>{res}</p>
                                    ))
                                }
                            </AccordionBody>
                        </Accordion>
                    ))
                }
                <h1 id='creditofaq' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mt-10'>Créditos</h1>
                {
                    creditofaq.map((faq, index) => (
                        <Accordion open={open[index + paginawebfaq.length]} key={index} icon={<Icon id={index + paginawebfaq.length} open={open[index + paginawebfaq.length]} />}>
                            <AccordionHeader className='text-sm text-azul hover:bg-gray-100' onClick={() => handleOpen(index + paginawebfaq.length)}>{faq.pregunta}</AccordionHeader>
                            <AccordionBody>
                                {
                                    faq.respuesta.map((res, index) => (
                                        <p className='pb-3' key={index}>{res}</p>
                                    ))
                                }
                            </AccordionBody>
                        </Accordion>
                    ))
                }
                <h1 id='garantiafaq' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mt-10'>Garantías</h1>
                {
                    garantiafaq.map((faq, index) => (
                        <Accordion open={open[index + paginawebfaq.length + creditofaq.length]} key={index} icon={<Icon id={index + paginawebfaq.length + creditofaq.length} open={open[index + paginawebfaq.length + creditofaq.length]} />}>
                            <AccordionHeader className='text-sm text-azul hover:bg-gray-100' onClick={() => handleOpen(index + paginawebfaq.length + creditofaq.length)}>{faq.pregunta}</AccordionHeader>
                            <AccordionBody>
                                {
                                    faq.respuesta.map((res, resIndex) => (
                                        <p className='pb-3' key={resIndex} dangerouslySetInnerHTML={{ __html: res }} />
                                    ))
                                }
                            </AccordionBody>
                        </Accordion>
                    ))
                }
                <h1 id='tiendafaq' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mt-10'>Tienda</h1>
                {
                    tiendafaq.map((faq, index) => (
                        <Accordion open={open[index + paginawebfaq.length + creditofaq.length + garantiafaq.length]} key={index} icon={<Icon id={index + paginawebfaq.length + creditofaq.length + garantiafaq.length} open={open[index + paginawebfaq.length + creditofaq.length + garantiafaq.length]} />}>
                            <AccordionHeader className='text-sm text-azul hover:bg-gray-100' onClick={() => handleOpen(index + paginawebfaq.length + creditofaq.length + garantiafaq.length)}>{faq.pregunta}</AccordionHeader>
                            <AccordionBody>
                                {
                                    faq.respuesta.map((res, resIndex) => (
                                        <p className='pb-3' key={resIndex} dangerouslySetInnerHTML={{ __html: res }} />
                                    ))
                                }
                            </AccordionBody>
                        </Accordion>
                    ))
                }
                <h1 id='enviosfaq' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mt-10'>Envíos</h1>
                {
                    enviosfaq.map((faq, index) => (
                        <Accordion open={open[index + paginawebfaq.length + creditofaq.length + garantiafaq.length + tiendafaq.length]} key={index}  icon={<Icon id={index + paginawebfaq.length + creditofaq.length + garantiafaq.length + tiendafaq.length} open={open[index + paginawebfaq.length + creditofaq.length + garantiafaq.length + tiendafaq.length]} />}>
                            <AccordionHeader className='text-sm text-azul hover:bg-gray-100' onClick={() => handleOpen(index + paginawebfaq.length + creditofaq.length + garantiafaq.length + tiendafaq.length)}>{faq.pregunta}</AccordionHeader>
                            <AccordionBody>
                                {
                                    faq.respuesta.map((res, resIndex) => (
                                        <p className='pb-3' key={resIndex} dangerouslySetInnerHTML={{ __html: res }} />
                                    ))
                                }
                            </AccordionBody>
                        </Accordion>
                    ))
                }
                <br />
                <div className='bg-gray-100 p-5 mx-auto w-full max-w-md flex flex-col lg:flex-row justify-between items-center gap-5'>
                    <div className='size-20 rounded-full flex justify-center items-center bg-azul'>
                        <FaEnvelope className='fill-white' size={42} />
                    </div>
                    <div className='flex-1'>
                        <p className='pb-1 text-center lg:text-left'>Contáctanos</p>
                        <p className='pb-1 text-center lg:text-left'>Contáctanos vía <a href='https://api.whatsapp.com/send?phone=528343110729' target='_blank' className='text-link underline'>WhatsApp</a> o por Teléfono al (834) 318 2700</p>
                        <p className='pb-1 text-center lg:text-left'><a className='text-link underline' target='_blank' href='mailto:contacto@muebleria-villarreal.com'>contacto@muebleria-villarreal.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default PreguntasFrecuentes