import { useState } from 'react'
import Layout from '../../layout/Layout'

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const BolsaTrabajo = () => {

    const [popup, setPopup] = useState(false);
    const [titulo, setTitulo] = useState('');

    const handlePopup = (title) => {
        setPopup(!popup)
        setTitulo(title)
        document.body.classList.toggle('overflow-hidden')
    }

  return (
    <Layout>
        {
            popup && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 lg:p-8 flex flex-col justify-center items-center gap-5 w-10/12 lg:w-4/12 max-h-[70lvh] overflow-hidden rounded-xl relative">
                        <div className='size-7 rounded-full shadow flex justify-center items-center text-[8px] font-negro absolute top-2 right-2 lg:top-5 lg:right-5 z-20 cursor-pointer transition-all bg-rojo text-white' onClick={() => handlePopup("")}>X</div>
                        <h2 className='text-2xl font-negro text-azul text-center'>{titulo}</h2>
                        <div className="overflow-y-auto text-sm">
                            <p>Requisitos:</p>
                            <p>- Sexo masculino</p>
                            <p>- Edad: entre 25 y 50 años</p>
                            <p>- Estudios mínimos de preparatoria terminada.</p>
                            <p>- Disponibilidad de horario.</p>
                            <p>- Indispensable cuente con licencia vigente.</p>
                            <p>- Experiencia en ventas.</p>
                            <p>- Gusto por atención al cliente.</p>
                            <p>- Buena presentación.</p>
                            <p>- Disponibilidad para viajar.</p>
                            <br />
                            <p>Ofrecemos:</p>
                            <p>- Sueldo base más comisiones.</p>
                            <p>- Seguro de vida</p>
                            <p>- Vehículo  y equipo móvil (herramienta de trabajo)</p>
                            <p>- Estabilidad laboral</p>
                            <p>- Prestaciones de ley</p>
                            <br />
                            <p>*&nbsp;Interesados presentarse con solicitud elaborada con fotografía, en horario de 09:00 a 19:00 hrs. en 15 y 16 Morelos #243, marcar a los teléfonos 8343182700 EXT. 150, 154 o 158 o enviar curriculum al correo <strong>recursos.humanos@mvc.com.mx</strong></p>
                        </div>
                    </div>
                </div>
            )
        }
        <div className="container mx-auto py-10 lg:py-10 px-4">
            <img src="/images/bolsa-de-trabajo.png" alt="" className='w-full' />
            <br />
            {/* <div className="flex flex-col lg:flex-row justify-center items-center gap-5 w-full">
                <img src="/images/bolsa_icono.png" alt="" className='w-52' />
                <div className="flex flex-col justify-center items-center lg:items-start gap-2 cursor-pointer w-full lg:w-5/12" onClick={() => handlePopup("Vendedor para Soto La Marina Tamaulipas")}>
                    <h2 className='text-2xl font-negro text-azul text-center lg:text-left'>Vendedor para Soto La Marina Tamaulipas</h2>
                    <p className='text-lg font-medio flex flex-row justify-start items-center gap-2 text-center lg:text-left'>
                        <img src="/images/icono-location.png" alt="" className='w-6' />
                        Soto La Marina, Tamps.
                    </p>

                </div>
            </div>
            <br />
            <div className="flex flex-col lg:flex-row justify-center items-center gap-5 w-full">
                <img src="/images/bolsa_icono.png" alt="" className='w-52' />
                <div className="flex flex-col justify-center items-center lg:items-start gap-2 cursor-pointer w-full lg:w-5/12" onClick={() => handlePopup("Vendedor cambaceo")}>
                    <h2 className='text-2xl font-negro text-azul text-center lg:text-left'>Vendedor cambaceo</h2>
                    <p className='text-lg font-medio flex flex-row justify-start items-center gap-2 text-center lg:text-left'>
                        <img src="/images/icono-location.png" alt="" className='w-6' />
                        Cd. Victoria, Tamps.
                    </p>
                </div>
            </div>
            <br />
            <div className="flex flex-col lg:flex-row justify-center items-center gap-5 w-full">
                <img src="/images/bolsa_icono.png" alt="" className='w-52' />
                <div className="flex flex-col justify-center items-center lg:items-start gap-2 cursor-pointer w-full lg:w-5/12" onClick={() => handlePopup("Vendedor para Tampico")}>
                    <h2 className='text-2xl font-negro text-azul text-center lg:text-left'>Vendedor para Tampico</h2>
                    <p className='text-lg font-medio flex flex-row justify-start items-center gap-2 text-center lg:text-left'>
                        <img src="/images/icono-location.png" alt="" className='w-6' />
                        Tampico, Tamps.
                    </p>
                </div>
            </div>
            <br />
            <div className="flex flex-col lg:flex-row justify-center items-center gap-5 w-full">
                <img src="/images/bolsa_icono.png" alt="" className='w-52' />
                <div className="flex flex-col justify-center items-center lg:items-start gap-2 cursor-pointer w-full lg:w-5/12" onClick={() => handlePopup("Vendedor para Nuevo Laredo")}>
                    <h2 className='text-2xl font-negro text-azul text-center lg:text-left'>Vendedor para Nuevo Laredo</h2>
                    <p className='text-lg font-medio flex flex-row justify-start items-center gap-2 text-center lg:text-left'>
                        <img src="/images/icono-location.png" alt="" className='w-6' />
                        Nuevo Laredo, Tamps.
                    </p>
                </div>
            </div> */}
            {/* <Splide hasTrack={false}
                options={{
                    perPage: 2,
                    perMove: 1,
                    type: 'loop',
                    pagination: true,
                    arrows: true,
                    gap: '3rem',
                    padding: "1rem"
                }}
            >
                <SplideTrack className="py-10 px-5">
                    <SplideSlide>
                        <div className="w-full flex flex-col justify-start items-center gap-2 p-5 border border-gray-200 rounded-2xl shadow-lg">
                            <img src='/images/logo_primario.png'  className='w-8/12'/>
                            <h2 className='uppercase font-negro pt-3 text-center text-lg'>¡Trabaja con Nosotros!</h2>
                            <div className='w-full flex flex-col justify-start items-start gap-2 pt-5'>
                                <p className='uppercase font-negro text-lg'>ESTAMOS CONTRATANDO</p>
                                <ul className='list-disc list-inside'>
                                    <li className='font-negro text-sm'>AUXILIAR DE PISO SUC. SAN AGUSTIN</li>
                                </ul>
                                <p className='uppercase text-lg pt-5'>Requisitos</p>
                                <ul className='list-disc list-inside'>
                                    <li className='text-sm'>Sexo Masculino</li>
                                    <li className='text-sm'>Estudios mínimo de preparatoria</li>
                                    <li className='text-sm'>Disponibilidad de horario</li>
                                    <li className='text-sm'>Manejo de vehiculo estándar</li>
                                </ul>
                                <p className=' text-sm pt-5'>Interesados presentarse con solicitud elaborada con fotografía, en horario de 09:00 a 19:00 hrs. En 15 y 16 Morelos #243, o mandar solicitud o curriculum vitae al correo <a target='_blank' href="mailto:recursos.humanos@mvc.com.mx" className='text-link'>recursos.humanos@mvc.com.mx</a>; o para más información llamar a los teléfonos <a target='_blank' href="tel:8343182700" className='text-link'>834 318 2700</a> ext. 150, 154 y 158; o al Whatsapp <a target='_blank' href="https://wa.me/18341317345" className='text-link'>834 131 7345</a>.</p>
                            </div>
                        </div>
                    </SplideSlide>
                </SplideTrack>
            </Splide> */}
            <div className='grid w-full grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10'>
                <div className="w-full flex flex-col justify-start items-center gap-2 p-5 border border-gray-200 rounded-2xl shadow-lg">
                    <img src='/images/logo_primario.png'  className='w-8/12'/>
                    <h2 className='uppercase font-negro pt-3 text-center text-lg'>¡Trabaja con Nosotros!</h2>
                    <div className='w-full flex flex-col justify-start items-start gap-2 pt-5'>
                        <p className='uppercase font-negro text-lg'>ESTAMOS CONTRATANDO</p>
                        <ul className='list-disc list-inside'>
                            <li className='font-negro text-sm'>AUXILIAR DE PISO SUC. SAN AGUSTIN</li>
                        </ul>
                        <p className='uppercase text-lg pt-5'>Requisitos</p>
                        <ul className='list-disc list-inside'>
                            <li className='text-sm'>Sexo Masculino</li>
                            <li className='text-sm'>Estudios mínimo de preparatoria</li>
                            <li className='text-sm'>Disponibilidad de horario</li>
                            <li className='text-sm'>Manejo de vehiculo estándar</li>
                        </ul>
                        <p className=' text-sm pt-5'>Interesados presentarse con solicitud elaborada con fotografía, en horario de 09:00 a 19:00 hrs. En 15 y 16 Morelos #243, o mandar solicitud o curriculum vitae al correo <a target='_blank' href="mailto:recursos.humanos@mvc.com.mx" className='text-link'>recursos.humanos@mvc.com.mx</a>; o para más información llamar a los teléfonos <a target='_blank' href="tel:8343182700" className='text-link'>834 318 2700</a> ext. 150, 154 y 158; o al Whatsapp <a target='_blank' href="https://wa.me/18341317345" className='text-link'>834 131 7345</a>.</p>
                    </div>
                </div>
                <div className="w-full flex flex-col justify-start items-center gap-2 p-5 border border-gray-200 rounded-2xl shadow-lg">
                    <img src='/images/logo_primario.png'  className='w-8/12'/>
                    <h2 className='uppercase font-negro pt-3 text-center text-lg'>¡Trabaja con Nosotros!</h2>
                    <div className='w-full flex flex-col justify-start items-start gap-2 pt-5'>
                        <p className='uppercase font-negro text-lg'>ESTAMOS CONTRATANDO</p>
                        <ul className='list-disc list-inside'>
                            <li className='font-negro text-sm'>CAJERA/O SAN AGUSTIN Y BODEGA</li>
                        </ul>
                        <p className='uppercase text-lg pt-5'>Requisitos</p>
                        <ul className='list-disc list-inside'>
                            <li className='text-sm'>Sexo Indistinto</li>
                            <li className='text-sm'>Experiencia en manejo de efectivo</li>
                            <li className='text-sm'>Estudios mínimo de preparatoria</li>
                            <li className='text-sm'>Disponibilidad de horario</li>
                            <li className='text-sm'>Atención al cliente</li>
                        </ul>
                        <p className=' text-sm pt-5'>Interesados presentarse con solicitud elaborada con fotografía, en horario de 09:00 a 19:00 hrs. En 15 y 16 Morelos #243, o mandar solicitud o curriculum vitae al correo <a target='_blank' href="mailto:recursos.humanos@mvc.com.mx" className='text-link'>recursos.humanos@mvc.com.mx</a>; o para más información llamar a los teléfonos <a target='_blank' href="tel:8343182700" className='text-link'>834 318 2700</a> ext. 150, 154 y 158; o al Whatsapp <a target='_blank' href="https://wa.me/18341317345" className='text-link'>834 131 7345</a>.</p>
                    </div>
                </div>
                <div className="w-full flex flex-col justify-start items-center gap-2 p-5 border border-gray-200 rounded-2xl shadow-lg">
                    <img src='/images/logo_primario.png'  className='w-8/12'/>
                    <h2 className='uppercase font-negro pt-3 text-center text-lg'>¡Trabaja con Nosotros!</h2>
                    <div className='w-full flex flex-col justify-start items-start gap-2 pt-5'>
                        <p className='uppercase font-negro text-lg'>ESTAMOS CONTRATANDO</p>
                        <ul className='list-disc list-inside'>
                            <li className='font-negro text-sm'>AUXILIAR DE BODEGA</li>
                        </ul>
                        <p className='uppercase text-lg pt-5'>Requisitos</p>
                        <ul className='list-disc list-inside'>
                            <li className='text-sm'>Sexo Masculino</li>
                            <li className='text-sm'>Estudios mínimo de preparatoria</li>
                            <li className='text-sm'>Experiencia en carga y descarga de mercancia.</li>
                            <li className='text-sm'>Disponibilidad de horario</li>
                        </ul>
                        <p className=' text-sm pt-5'>Interesados presentarse con solicitud elaborada con fotografía, en horario de 09:00 a 19:00 hrs. En 15 y 16 Morelos #243, o mandar solicitud o curriculum vitae al correo <a target='_blank' href="mailto:recursos.humanos@mvc.com.mx" className='text-link'>recursos.humanos@mvc.com.mx</a>; o para más información llamar a los teléfonos <a target='_blank' href="tel:8343182700" className='text-link'>834 318 2700</a> ext. 150, 154 y 158; o al Whatsapp <a target='_blank' href="https://wa.me/18341317345" className='text-link'>834 131 7345</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default BolsaTrabajo