import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'

import { Select, Option, Input } from "@material-tailwind/react";
import axios from 'axios';
import { TbLoader3 } from 'react-icons/tb';

const CrearMesaRegalos = () => {

    const [select, setSelect] = useState("null");
    const [user, setUser] = useState(false);
    const [loading, setLoading] = useState(false);

    //BODA STATES
    const [festejado1, setFestejado1] = useState('');
    const [nombre1, setNombre1] = useState('');
    const [apellidoPaterno1, setApellidoPaterno1] = useState('');
    const [apellidoMaterno1, setApellidoMaterno1] = useState('');
    const [festejado2, setFestejado2] = useState('');
    const [nombre2, setNombre2] = useState('');
    const [apellidoPaterno2, setApellidoPaterno2] = useState('');
    const [apellidoMaterno2, setApellidoMaterno2] = useState('');
    const [fechaEvento, setFechaEvento] = useState('');
    const [nombreEvento, setNombreEvento] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');

    // OTRA CELEBRACION STATES
    const [nombre, setNombre] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [fechaEventoOtro, setFechaEventoOtro] = useState('');
    const [nombreEventoOtro, setNombreEventoOtro] = useState('');
    const [emailOtro, setEmailOtro] = useState('');
    const [telefonoOtro, setTelefonoOtro] = useState('');

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    const isValidPhone = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const textLength = (text) => {
        return text.length >= 3;
    };

    useEffect(() => {
        try {
            const loged = JSON.parse(localStorage.getItem('eyJhbGciOiJIUzI1NiJ9'));
            if (!loged) {
                window.location.href = '/login';
                console.log('No hay usuario logeado');
            } else {
                setUser(true);
            }
        } catch (error) {
            console.error('Error parsing JSON from localStorage', error);
            window.location.href = '/login';
        }
    }, []);

    useEffect(() => {
        const bodaDiv = document.getElementById("bodaDiv");
        const otroDiv = document.getElementById("otroDiv");

        if (select === "Boda") {
            bodaDiv.classList.remove("hidden");
            bodaDiv.classList.add("flex");
            otroDiv.classList.add("hidden");
            otroDiv.classList.remove("flex");
        } else if(select === "null") {
            otroDiv.classList.add("hidden");
            otroDiv.classList.remove("flex");
            bodaDiv.classList.add("hidden");
            bodaDiv.classList.remove("flex");
        } else {
            bodaDiv.classList.add("hidden");
            bodaDiv.classList.remove("flex");
            otroDiv.classList.remove("hidden");
            otroDiv.classList.add("flex");
        }
    }, [select]);

    const handleSubmit = () => {
        setLoading(true);
        // axios.post('/mails/mesacreada', {
        axios.post('https://competent-galileo.72-167-48-199.plesk.page/api/mesacreada', {
            tipocelebracion: select,
            boda_festejado1: festejado1,
            boda_nombre1: nombre1,
            boda_apellidopat1: apellidoPaterno1,
            boda_apellidomat1: apellidoMaterno1,
            boda_festejado2: festejado2,
            boda_nombre2: nombre2,
            boda_apellidopat2: apellidoPaterno2,
            boda_apellidomat2: apellidoMaterno2,
            boda_fecha: fechaEvento,
            boda_nombreevento: nombreEvento,
            otro_nombre: nombre,
            otro_apellidopat: apellidoPaterno,
            otro_apellidomat: apellidoMaterno,
            otro_fecha: fechaEventoOtro,
            otro_nombreevento: nombreEventoOtro,
            boda_mail: email,
            boda_tel: telefono,
            otro_mail: emailOtro,
            otro_tel: telefonoOtro
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            // console.log(response);
            window.location.href = '/mesacreada';
        })
        .catch(error => {
            window.location.href = '/mesacreada';
        });
        // window.location.href = '/mesacreada';
    };

  return (
    <Layout>
        {
            !user && (
                <div className='w-full bg-white h-screen flex flex-col justify-center items-center fixed top-0 left-0 z-50'></div>
            )
        }
        {
            loading &&
                <div className="w-full h-full flex justify-center items-center fixed z-50 bg-white/95 top-0 left-0">
                    <TbLoader3 size={60} className='stroke-azul animate-spin' />
                </div>
        }
        <section className='w-full px-[5vw] h-[40vh] bg-azul pt-10 flex flex-col justify-start items-center relative z-0'>
            <h1 className='text-center text-white text-2xl lg:text-4xl font-medio '>¡Bienvenido a tu nueva Mesa de Regalos!</h1>
            <h2 className='pt-2 text-white text-sm lg:text-lg text-center'>Es muy fácil y rápido, solo ingresa tus datos.</h2>
        </section>
        <section className='w-full z-10 relative pb-20 px-4'>
            <div className='bg-white w-full max-w-5xl lg:min-h-[30vh] p-6 lg:py-10 lg:px-12 mx-auto -mt-[20vh] flex flex-col justify-start items-start gap-4' style={{ boxShadow: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)" }}>
                <span className='text-xs font-medio'>* Campos requeridos</span>
                <Select size='lg' label="Selecciona tu celebración" onChange={(e) => setSelect(e)}>
                    <Option value='Boda'>Boda</Option>
                    <Option value='Baby Shower'>Baby Shower</Option>
                    <Option value='Bautizo'>Bautizo</Option>
                    <Option value='Confirmación'>Confirmación</Option>
                    <Option value='Primera Comunión'>Primera Comunión</Option>
                    <Option value='Otro'>Otro</Option>
                </Select>
                <div className="w-full pt-2 flex-col justify-start items-start gap-3 hidden" id="bodaDiv">
                    <p className='font-medio'>Festejado(a) 1</p>
                    <Select size='lg' label="Festejado(a) 1" onChange={(e) => setFestejado1(e)}>
                        <Option value='Novio'>Novio</Option>
                        <Option value='Novia'>Novia</Option>
                    </Select>
                    <Input size='lg' label='Nombre *' value={nombre1} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setNombre1("");
                        } else {
                            setNombre1(filteredValue);
                        }
                    }} />
                    <Input size='lg' label='Apellido Paterno *' value={apellidoPaterno1} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setApellidoPaterno1("");
                        } else {
                            setApellidoPaterno1(filteredValue);
                        }
                    }} />
                    <Input size='lg' label='Apellido Materno' value={apellidoMaterno1} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setApellidoMaterno1("");
                        } else {
                            setApellidoMaterno1(filteredValue);
                        }
                    }} />
                    <p className='font-medio pt-4'>Festejado(a) 2</p>
                    <Select size='lg' label="Festejado(a) 2" onChange={(e) => setFestejado2(e)}>
                        <Option value='Novio'>Novio</Option>
                        <Option value='Novia'>Novia</Option>
                    </Select>
                    <Input size='lg' label='Nombre *' value={nombre2} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setNombre2("");
                        } else {
                            setNombre2(filteredValue);
                        }
                    }} />
                    <Input size='lg' label='Apellido Paterno *' value={apellidoPaterno2} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setApellidoPaterno2("");
                        } else {
                            setApellidoPaterno2(filteredValue);
                        }
                    }} />
                    <Input size='lg' label='Apellido Materno' value={apellidoMaterno2} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setApellidoMaterno2("");
                        } else {
                            setApellidoMaterno2(filteredValue);
                        }
                    }} />
                    <br />
                    <Input size='lg' label='Fecha del Evento' type='date' value={fechaEvento} onChange={(e) => setFechaEvento(e.target.value)} />
                    <Input size='lg' label='Nombre del Evento *' value={nombreEvento} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setNombreEvento("");
                        } else {
                            setNombreEvento(filteredValue);
                        }
                    }} />
                    <Input size='lg' label='Email de Contacto *' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    <Input size='lg' label='Teléfono de Contacto *' type='tel' value={telefono} maxLength={10} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^0-9]/g, ''); // Allow only numbers
                        setTelefono(filteredValue);
                    }} />
                    <button onClick={handleSubmit} className={`bg-link py-2 px-4 text-white rounded-md mx-auto w-full lg:w-fit transition-all ${!textLength(nombre1) || !textLength(apellidoPaterno1) || !textLength(fechaEvento) || !textLength(nombreEvento) || !isValidEmail(email) || !isValidPhone(telefono) || !textLength(festejado1) || !textLength(nombre2) || !textLength(apellidoPaterno2) || !textLength(festejado2) ? "opacity-50" : "opacity-100"}`} disabled={
                        !textLength(nombre1) || !textLength(apellidoPaterno1) || !textLength(fechaEvento) || !textLength(nombreEvento) || !isValidEmail(email) || !isValidPhone(telefono) || !textLength(festejado1) || !textLength(nombre2) || !textLength(apellidoPaterno2) || !textLength(festejado2)
                    }>
                        Enviar Información
                    </button>
                </div>
                <div className="w-full pt-2 flex-col justify-start items-start gap-3 hidden" id="otroDiv">
                    <p className='font-medio'>Festejado(a)</p>
                    <Input size='lg' label='Nombre *' value={nombre} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setNombre("");
                        } else {
                            setNombre(filteredValue);
                        }
                    }} />
                    <Input size='lg' label='Apellido Paterno *' value={apellidoPaterno} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setApellidoPaterno("");
                        } else {
                            setApellidoPaterno(filteredValue);
                        }
                    }} />
                    <Input size='lg' label='Apellido Materno' value={apellidoMaterno} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setApellidoMaterno("");
                        } else {
                            setApellidoMaterno(filteredValue);
                        }
                    }} />
                    <br />
                    <Input size='lg' label='Fecha del Evento' type='date' value={fechaEventoOtro} onChange={(e) => setFechaEventoOtro(e.target.value)} />
                    <Input size='lg' label='Nombre del Evento *' value={nombreEventoOtro} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^a-zA-Z\s]/g, ''); // Allow only letters and spaces
                        if(filteredValue === ' ') {
                            setNombreEventoOtro("");
                        } else {
                            setNombreEventoOtro(filteredValue);
                        }
                    }} />
                    <Input size='lg' label='Email de Contacto *' value={emailOtro} onChange={(e) => setEmailOtro(e.target.value)} type='email' />
                    <Input size='lg' label='Teléfono de Contacto *' value={telefonoOtro} maxLength={10} onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^0-9]/g, ''); // Allow only numbers
                        setTelefonoOtro(filteredValue);
                    }} type='tel' />
                    <button onClick={handleSubmit} className={`bg-link py-2 px-4 text-white rounded-md mx-auto w-full lg:w-fit transition-all ${!textLength(nombre) || !textLength(apellidoPaterno) || !textLength(fechaEventoOtro) || !textLength(nombreEventoOtro) || !isValidEmail(emailOtro) || !isValidPhone(telefonoOtro) ? "opacity-50" : "opacity-100"}`} disabled={
                        !textLength(nombre) || !textLength(apellidoPaterno) || !textLength(fechaEventoOtro) || !textLength(nombreEventoOtro) || !isValidEmail(emailOtro) || !isValidPhone(telefonoOtro)
                    }>
                        Enviar Información
                    </button>
                </div>
                <br />
            </div>
        </section>
    </Layout>
  )
}

export default CrearMesaRegalos