import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import Departamento from "./pages/Departamento"
import Producto from "./pages/Producto"
import Carrito from "./pages/Carrito"
import TramitarCredito from "./pages/TramitarCredito"
import QuienesSomos from "./pages/Empresa/QuienesSomos"
import Sucursales from "./pages/Empresa/Sucursales"
import BolsaTrabajo from "./pages/Empresa/BolsaTrabajo"
import Credito from "./pages/Empresa/Credito"
import Garantias from "./pages/Empresa/Garantias"
import AvisodePrivacidad from "./pages/Empresa/AvisodePrivacidad"
import MesaRegalosIndex from "./pages/MesaRegalos/MesaRegalosIndex"
import CrearMesaRegalos from "./pages/MesaRegalos/CrearMesaRegalos"
import Login from "./pages/User/Login"
import Register from "./pages/User/Register"
import PasswordReset from "./pages/User/PasswordReset"
import MesaRegalosConsult from "./pages/MesaRegalos/MesaRegalosConsult"
import MesaRegalosSearch from "./pages/MesaRegalos/MesaRegalosSearch"
import PreguntasFrecuentes from "./pages/Empresa/PreguntasFrecuentes"
import Search from "./pages/Search"
import ErrorPage from "./pages/ErrorPage"
import Checkout from "./pages/Checkout"
import MesaCreada from "./pages/MesaRegalos/MesaCreada"
import Cliente from "./pages/User/Cliente"
import Successfull from "./pages/Successfull"
import PoliticasdeDevolucion from "./pages/Empresa/PoliticasdeDevolucion"
import OTP from "./pages/User/OTP"
import TerminosCondiciones from "./pages/Empresa/TerminosCondiciones"
import PruebaPago from "./pages/PruebaPago"
import ConfirmResetPassword from "./pages/User/ConfirmResetPassword"
import PruebaFactura from "./pages/PruebaFactura"

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Departamento/:departamento/:grupo" element={<Departamento />} />
        <Route path="/search" element={<Search />} />
        <Route path="/Articulo/:id/:grupo" element={<Producto />} />
        <Route path="/carrito" element={<Carrito />} />
        <Route path="/CarritoCheckOut" element={<Checkout />} />
        <Route path="/payment/pay" element={<Successfull />} />

        //Empresa
        <Route path="/Empresa/QuienesSomos" element={<QuienesSomos />} />
        <Route path="/Empresa/Sucursales" element={<Sucursales />} />
        <Route path="/Empresa/BolsaTrabajo" element={<BolsaTrabajo />} />
        <Route path="/Empresa/Credito" element={<Credito />} />
        <Route path="/Empresa/Garantias" element={<Garantias />} />
        <Route path="/Empresa/AvisodePrivacidad" element={<AvisodePrivacidad />} />
        <Route path="/Empresa/PreguntasFrecuentes" element={<PreguntasFrecuentes />} />
        <Route path="/Empresa/PoliticasdeDevolucion" element={<PoliticasdeDevolucion />} />
        <Route path="/Empresa/TerminosyCondiciones" element={<TerminosCondiciones />} />


        //Mesa
        <Route path="/mesaregalos" element={<MesaRegalosIndex />} />
        <Route path="/mesaregalos/:id" element={<MesaRegalosConsult />} />
        <Route path="/mesaregalos/buscar" element={<MesaRegalosSearch />} />
        <Route path="/nuevamesaregalos" element={<CrearMesaRegalos />} />
        <Route path="/mesacreada" element={<MesaCreada />} />

        //Tramitar Credito
        <Route path="/tramitar-credito" element={<TramitarCredito />} />

        //CLIENTE
        <Route path="/login" element={<Login />} />
        <Route path="/UserRegistrationForm" element={<Register />} />
        <Route path="/password/reset" element={<PasswordReset />} />
        <Route path="/cliente" element={<Cliente />} />
        <Route path="/otp/:token" element={<OTP />} />
        <Route path="/password/reset/:key" element={<ConfirmResetPassword />} />

        // Error page
        <Route path="*" element={<ErrorPage />} />
        {/* <Route path="/pruebapago" element={<PruebaPago />} />
        <Route path="/pruebafactura" element={<PruebaFactura />} /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default App
