import React from "react";
import Layout from "../../layout/Layout";
import { HiMapPin } from "react-icons/hi2";
import { MdWatchLater } from "react-icons/md";
import { FaPhone } from "react-icons/fa";

const Sucursales = () => {
  return (
    <Layout>
      <section className="container mx-auto px-4 flex flex-col justify-start items-start gap-5 py-10 lg:py-16">
        <h1 className="font-medio text-xl lg:text-2xl text-left tracking-tight text-azul pb-5">
          SUCURSALES
        </h1>
        <div className="flex flex-col lg:flex-row w-full justify-start items-center lg:items-start gap-5 pb-5 lg:pb-0 p-5 bg-white rounded-xl shadow lg:shadow-none">
          <div className="w-full lg:w-4/12">
            <iframe
              className="w-full aspect-video"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2582.675699727896!2d-99.15156360264571!3d23.731148772374905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x68fad754ffc63d40!2sMuebleria%20Villarreal!5e0!3m2!1ses!2smx!4v1605041599193!5m2!1ses!2smx"
              frameborder="0"
            ></iframe>
          </div>
          <div className="w-full lg:w-5/12">
            <h5 className="text-azul font-negro pb-5">MATRIZ</h5>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <HiMapPin className="fill-azul" size={20} /> 15 Hidalgo No. 301
              Ote. Zona Centro
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <MdWatchLater className="fill-azul" size={20} /> 9:00 am a 8:00 pm
              de Lunes a Sábado y Domingos de 10:00 am a 6:00 pm
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <FaPhone className="fill-azul" size={18} /> Tel. (834) 318 27 00
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-start items-center lg:items-start gap-5 pb-5 lg:pb-0 p-5 bg-white rounded-xl shadow lg:shadow-none">
          <div className="w-full lg:w-4/12">
            <iframe
              className="w-full aspect-video"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d913.0697246150429!2d-99.15002720719167!3d23.7374318983477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x867953017783d817%3A0x8e684765f25ba0df!2sMuebleria%20Villarreal%20Caballero%20(Carrera)!5e0!3m2!1ses!2smx!4v1605044169075!5m2!1ses!2smx"
              frameborder="0"
            ></iframe>
          </div>
          <div className="w-full lg:w-5/12">
            <h5 className="text-azul font-negro pb-5">CARRERA</h5>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <HiMapPin className="fill-azul" size={20} />
              14 y 15 Carrera Torres
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <MdWatchLater className="fill-azul" size={20} />
              9:00 am a 8:00 pm de Lunes a Sábado
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <FaPhone className="fill-azul" size={18} />
              Tel. (834) 316 90 93, 312 91 83
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-start items-center lg:items-start gap-5 pb-5 lg:pb-0 p-5 bg-white rounded-xl shadow lg:shadow-none">
          <div className="w-full lg:w-4/12">
            <iframe
              className="w-full aspect-video"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4342.784776530334!2d-99.13157233536575!3d23.753364221336444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x729547a4e0b435dc!2sMuebleria%20Villarreal%20Caballero%20(Sucursal%20Bodega)!5e0!3m2!1ses!2smx!4v1605044608097!5m2!1ses!2smx"
              frameborder="0"
            ></iframe>
          </div>
          <div className="w-full lg:w-5/12">
            <h5 className="text-azul font-negro pb-5">BODEGA</h5>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <HiMapPin className="fill-azul" size={20} />
              Fracc. Teocaltiche
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <MdWatchLater className="fill-azul" size={20} />
              9:00 am a 8:00 pm de Lunes a Sábado y Domingos de 11:00 am a 7:00
              pm
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <FaPhone className="fill-azul" size={18} />
              Tel. (834) 305 91 08
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-start items-center lg:items-start gap-5 pb-5 lg:pb-0 p-5 bg-white rounded-xl shadow lg:shadow-none">
          <div className="w-full lg:w-4/12">
            <iframe
              className="w-full aspect-video"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d767.6344718235064!2d-99.1480814899778!3d23.76500708456306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x96b6f18614791ef8!2sMuebleria%20Villarreal%20Caballero%20(San%20Agust%C3%ADn)!5e0!3m2!1ses!2smx!4v1605044529137!5m2!1ses!2smx"
              frameborder="0"
            ></iframe>
          </div>
          <div className="w-full lg:w-5/12">
            <h5 className="text-azul font-negro pb-5">SAN AGUSTIN</h5>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <HiMapPin className="fill-azul" size={20} />
              Fracc. Villarreal
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <MdWatchLater className="fill-azul" size={20} />
              9:00 am a 8:00 pm de Lunes a Sábado y Domingos de 11:00 am a 7:00
              pm
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <FaPhone className="fill-azul" size={18} />
              Tel. (834) 185 41 30
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-start items-center lg:items-start gap-5 pb-5 lg:pb-0 p-5 bg-white rounded-xl shadow lg:shadow-none">
          <div className="w-full lg:w-4/12">
            <iframe
              className="w-full aspect-video"
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d228.27733878832737!2d-99.14761758065383!3d23.731776114388865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1674429325470!5m2!1ses-419!2smx"
              frameborder="0"
            ></iframe>
          </div>
          <div className="w-full lg:w-5/12">
            <h5 className="text-azul font-negro pb-5">EXPRESS</h5>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <HiMapPin className="fill-azul" size={20} />
              11 y 12 Hidalgo N°637 Zona Centro
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <MdWatchLater className="fill-azul" size={20} />
              9:00 am a 7:00 pm de Lunes a Sábado
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <FaPhone className="fill-azul" size={18} />
              Tel. (834) 318 27 10
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-start items-center lg:items-start gap-5 pb-5 lg:pb-0 p-5 bg-white rounded-xl shadow lg:shadow-none">
          <div className="w-full lg:w-4/12">
            <iframe
              className="w-full aspect-video"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.703446746318!2d-97.5051939844807!3d25.87396575899871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866f9495208451df%3A0xade8d92b51c99065!2sMuebleria%20Villarreal%20Caballero%20(Matamoros)!5e0!3m2!1ses!2smx!4v1605049561137!5m2!1ses!2smx"
              frameborder="0"
            ></iframe>
          </div>
          <div className="w-full lg:w-5/12">
            <h5 className="text-azul font-negro pb-5">MATAMOROS</h5>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <HiMapPin className="fill-azul" size={20} />
              Calle Sexta No. 408 E/Juarez y Terán
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <MdWatchLater className="fill-azul" size={20} />
              9:00 am a 8:00 pm de Lunes a Sábado
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <FaPhone className="fill-azul" size={18} />
              Tel. (868) 813 22 21
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-start items-center lg:items-start gap-5 pb-5 lg:pb-0 p-5 bg-white rounded-xl shadow lg:shadow-none">
          <div className="w-full lg:w-4/12">
            <iframe
              className="w-full aspect-video"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d912.8342981488678!2d-98.20326127144715!3d23.771004125991094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86798dc62911c735%3A0x212a3848dd1d0564!2sA%20La%20Pesca%20501-Local%207%20y%208%2C%203%20de%20Septiembre%2C%2087670%20Soto%20la%20Marina%2C%20Tamps.!5e0!3m2!1ses!2smx!4v1715891202266!5m2!1ses!2smx"
              frameborder="0"
            ></iframe>
          </div>
          <div className="w-full lg:w-5/12">
            <h5 className="text-azul font-negro pb-5">SOTO LA MARINA</h5>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <HiMapPin className="fill-azul" size={20} />
              Carretera a la Pesca # 501 Local 7 y 8 Col 3 de Septiembre 87670
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <MdWatchLater className="fill-azul" size={20} />
              9:00 am a 7:00 pm de Lunes a Sábado
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <FaPhone className="fill-azul" size={18} />
              Tel. (835) 327 08 69
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-start items-center lg:items-start gap-5 pb-5 lg:pb-0 p-5 bg-white rounded-xl shadow lg:shadow-none">
          <div className="w-full lg:w-4/12">
            <iframe
              className="w-full aspect-video"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.660168352834!2d-99.15758861253211!3d23.759494933427824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8679537166d720c9%3A0x725df979df64caa4!2sMichoac%C3%A1n%20319%2C%20Santa%20Martha%2C%2087049%20Cdad.%20Victoria%2C%20Tamps.!5e0!3m2!1ses-419!2smx!4v1716334927166!5m2!1ses-419!2smx"
              frameborder="0"
            ></iframe>
          </div>
          <div className="w-full lg:w-5/12">
            <h5 className="text-azul font-negro pb-5">SAN LUISITO</h5>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <HiMapPin className="fill-azul" size={20} />
              Michoacán 319, Santa Martha, 87049 Cdad. Victoria, Tamps.
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <MdWatchLater className="fill-azul" size={20} />
              10:00 am a 7:00 pm de Lunes a Sábado
            </p>
            <p className="pl-2 text-sm flex flex-row justify-start items-center gap-2 pb-2">
              <FaPhone className="fill-azul" size={18} />
              Tel. (834) 316 87 57
            </p>
          </div>
        </div>
        <p className="w-full text-sm italic lg:w-9/12 mx-auto">* Muebleria Villarreal Caballero solo cuenta con sucursales en Cd. Victoria, Matamoros y Soto la Marina Tamaulipas</p>
      </section>
    </Layout>
  );
};

export default Sucursales;
