import React from 'react'
import Layout from '../../layout/Layout'

const MesaCreada = () => {
  return (
    <Layout>
        <section className='w-full px-[5vw] h-[40vh] bg-white pt-10 flex flex-col justify-center items-center relative z-0'>
            <h1 className='text-center text-azul text-2xl lg:text-4xl font-medio '>¡Gracias por tu solicitud!</h1>
            <h2 className='pt-2 text-dark text-sm lg:text-lg text-center pb-10'>En breve, nos comunicaremos contigo</h2>
            <a href="/" className='w-full lg:w-fit bg-darkGris text-center py-3 lg:py-2 px-4 text-white text-sm rounded'>Regresar a Tienda</a>
        </section>
    </Layout>
  )
}

export default MesaCreada