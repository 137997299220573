import React, { useEffect, useState, useRef } from 'react'
import Layout from '../../layout/Layout'
import { TbLoader3 } from "react-icons/tb";
import { FaEye } from "react-icons/fa";

import { Alert } from "@material-tailwind/react";

import { Input } from '@material-tailwind/react'

import { Toaster, toast } from 'sonner';

const Login = () => {

    const buttonRef = useRef(null);

    const [loading, setLoading] = useState(false)
    const [showPw, setShowPw] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isRegister, setIsRegister] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('register')) {
            setIsRegister(true);
        }
        const user = JSON.parse(localStorage.getItem('eyJhbGciOiJIUzI1NiJ9'))
        if(user) {
            window.location.href = '/'
        }
    }, []);

    const handleLogin = () => {
        setLoading(true)
        fetch('https://www.muebleria-villarreal.com/apimv/loginController.php?email=' + email + '&password=' + password)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if(data.result == "successful") {
                    localStorage.setItem('eyJhbGciOiJIUzI1NiJ9', JSON.stringify(data.loginInfo))
                    window.location.href = '/'
                } else if(data.result == "error_pw") {
                    toast.error('Contraseña incorrecta. Favor de intentar nuevamente.', {
                        duration: 3000
                    })
                    setLoading(false)
                } else if(data.result == "error_email") {
                    toast.error('Correo no registrado. Favor de registrarse.', {
                        duration: 3000
                    })
                    setLoading(false)
                } else {
                    toast.error('Usuario y/o contraseña incorrecta. Favor de intentar nuevamente.', {
                        duration: 3000
                    })
                    setLoading(false)
                }
            })
    }

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateEmail = (value) => {
        setEmail(value.replace(/\s/g, ''));
    };

  return (
    <Layout>
        <Toaster richColors closeButton position="top-right" />
        <section className='w-full pt-20 pb-28 min-h-[60vh] bg-[url("/images/loginBG.jpg")] bg-cover bg-center flex justify-center items-center'>
            {
                loading &&
                <div className='w-full h-lvh bg-black bg-opacity-70 flex justify-center items-center fixed top-0 left-0 z-40'>
                    <TbLoader3 className='stroke-white animate-spin h-20 w-20' />
                </div>
            }
            <div className='container mx-auto flex flex-col lg:flex-row justify-center items-start gap-20 px-4'>
                <div className='w-full lg:w-4/12 flex flex-col justify-start items-start gap-2'>
                    <h1 className='text-xl lg:text-3xl text-dark'>Inicia Sesión</h1>
                    <h1 className='text-lg lg:text-2xl text-dark'>Así, todo es más fácil</h1>
                    <p className='text-azul font-medio text-lg lg:text-2xl'>Beneficios</p>
                    <ul className=' list-disc list-inside'>
                        <li>Protege tus datos e información de pago.</li>
                        <li>Compra más rápido en tus siguientes visitas.</li>
                        <li>Guarda distintas direcciones de entrega.</li>
                    </ul>
                </div>
                <div className='w-full lg:w-4/12 bg-white p-5 rounded-lg flex flex-col justify-center items-center gap-2' style={{ boxShadow: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)" }}>
                    <h2 className='text-xl text-azul font-negro text-center'>¡Hola de nuevo!</h2>
                    <p className='text-center text-xs text-azul pb-5'>Inicia sesión con tu correo electrónico</p>
                    {
                        isRegister &&
                        <Alert className='bg-link/60 py-3 -mt-3 mb-3'><span className='text-xs leading-none uppercase font-medio text-white'>Usuario registrado correctamente.</span></Alert>
                    }
                    <Input size='lg' label='Correo *' type='email' value={email} onChange={(e) => validateEmail(e.target.value)} onKeyDown={(e) => {
                        if(e.code === "Space") e.preventDefault();
                        if(e.code === "Enter") {
                            document.getElementById("buttonRef").click();
                        }
                    }} />
                    <Input icon={<FaEye className='cursor-pointer fill-azul' size={20} onClick={() => setShowPw(!showPw)} />} size='lg' label='Contraseña *' type={showPw ? "text" : "password"}  value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => {
                        if(e.code === "Enter") {
                            document.getElementById("buttonRef").click();
                        }
                    }} />
                    <a href="/password/reset" className='text-link text-xs underline w-full'>¿Olvidaste tu contraseña?</a>
                    {
                        !isEmailValid(email) || password.length < 1 ?
                        <button className='bg-azul py-2 px-4 text-white rounded-md mx-auto w-full mt-3 transition-all hover:bg-link opacity-40' disabled>Acceder</button>
                        :
                        <button ref={buttonRef} id="buttonRef" className='bg-azul py-2 px-4 text-white rounded-md mx-auto w-full mt-3 transition-all hover:bg-link' onClick={handleLogin}>Acceder</button>
                    }
                    <br />
                    <h2 className='text-xl text-azul font-negro text-center'>¿Eres nuevo?</h2>
                    <a href="/UserRegistrationForm" className='bg-white text-center py-3 px-4 text-azul border border-azul rounded-md mx-auto w-full mt-0 text-sm transition-all hover:bg-link hover:text-white hover:border-link'>Crea una cuenta</a>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default Login