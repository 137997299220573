import React from 'react'
import Layout from '../../layout/Layout'

const AvisodePrivacidad = () => {
  return (
    <Layout>
        <div className="container mx-auto bg-white p-5 shadow my-10 flex flex-col justify-start items-start gap-5">
            <h1 className='font-medio text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight'>AVISO DE PRIVACIDAD</h1>
            <p className='text-sm pl-5'>La entidad Responsable Mueblería Villarreal Caballero SA de CV, la cual tiene su
            domicilio en calle 15 Hidalgo ote. 301, CP 87000, Zona Centro, en Cd. Victoria,
            Tamaulipas; es responsable de recabar sus datos personales, atendiendo su uso y
            su protección, bajo los principios de <strong>licitud, finalidad, lealtad, consentimiento,
            calidad, proporcionalidad, información y responsabilidad.</strong></p>

            <p className='text-sm pl-5'>Le hacemos saber, que la información personal que usted nos proporcione, será utilizada para proveer de nuestros servicios, productos, promociones, concursos, y encuestas de satisfacción que comercializamos, así como también informarle sobre cambios en los mismos, por medio de mensajes de texto, correos electrónicos, llamadas telefónicas.  .</p>

            <p className='text-sm pl-5'>Para las ﬁnalidades antes mencionadas, requerimos se sirva proporcionarnos de
            manera libre y voluntaria, sus datos personales como son: su nombre, domicilios,
            teléfonos, correo electrónico, Clave Única de Registro de Población y Registro
            Federal de Contribuyentes.</p>

            <p className='text-sm pl-5'>Las transferencias de datos personales realizadas por el Responsable serán en
            términos a lo establecido en el artículo 37 de la Ley, y en cumplimiento al Artículo
            68 del Reglamento.</p>

            <p className='text-sm pl-5'><b>IMÁGENES O VIDEOS OBTENIDOS POR LAS CÁMARAS DE VIDEO VIGILANCIA.</b><br />Las imágenes o videos tomados por las cámaras de Seguridad de Mueblería Villarreal, son con fines de seguridad dentro y fuera de la empresa, mismos que son resguardados y con acceso restringido y su uso responderá, a solicitudes o requerimientos de las autoridades&nbsp;competentes.</p>

            <p className='text-sm pl-5'>Asimismo, le informamos que Mueblería Villarreal Caballero S. A. de C. V., podrá
            transferir sus datos personales únicamente a sus empresas aﬁliadas y
            proveedores de servicios seleccionados, para apoyar en las actividades de
            promoción y comercialización de nuestros bienes y servicios, asintiendo que en
            ninguna circunstancia, comercializaremos información respecto de sus datos
            personales.</p>

            <p className='text-sm pl-5'>El uso de esta información, está protegida por la Ley Federal de Protección de
            Datos Personales en Posesión de Particulares, y Usted tiene derecho a acceder,
            rectiﬁcar y cancelar sus datos personales, así como de oponerse al tratamiento de
            los mismos (Derechos ARCO), o revocar el consentimiento que para tal ﬁn nos
            haya otorgado.</p>

            <p className='text-sm pl-5'>Mueblería Villarreal Caballero SA de CV., podrá revelar o permitir el acceso a los
            datos personales que Usted nos proporcione, para cumplir con la legislación
            aplicable o por requerimiento de Autoridad competente.</p>

            <p className='text-sm pl-5'>Para ejercitar sus derechos ARCO, podrá realizar su solicitud contactándose por
            medio de la cuenta de correo electrónico <a href="mailto:contacto@muebleria-villarreal.com">contacto@muebleria-villarreal.com</a>, o
            bien, visitar nuestra página de Internet <a href="https://www.muebleria-villarreal.com">www.muebleria-villarreal.com</a>, en donde
            podrá consultar toda la información necesaria, debiendo obtener respuesta dentro
            del los siguientes 15 dias.</p>

            <p className='text-sm pl-5'>Si no maniﬁesta oposición alguna a este Aviso de Privacidad, a través de los
            medios que hemos puesto a su disposición, se entenderá que Usted ha otorgado
            su consentimiento para el tratamiento de sus datos personales.</p>

            <p className='text-sm pl-5'>El domicilio de la Unidad de Transparencia se encuentra ubicado en <strong>Abasolo No.
            1002, Zona Centro, C.P. 87000, Ciudad Victoria, Tamaulipas.</strong></p>

            <p className='text-sm pl-5'>Cualquier modiﬁcación o actualización del presente Aviso de Privacidad estará
            disponible en nuestra página de internet:&nbsp;<a href="https://www.muebleria-villarreal.com">www.muebleria-villarreal.com</a>&nbsp;AVISO DE
            PRIVACIDAD</p>
            <br />
            <p className="text-gris italic text-sm pl-5 font-medio">Fecha de última actualización 08 de Septiembre de 2021.</p>
        </div>
    </Layout>
    )}

export default AvisodePrivacidad