import React from 'react'
import Layout from '../layout/Layout'

const ErrorPage = () => {
  return (
    <Layout>
      <section class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div class="text-center">
              <p class="text-base font-medio text-azul">Oops!</p>
              <h1 class="mt-4 text-3xl font-negro tracking-tight text-azul sm:text-5xl uppercase">Pagina no encontrada</h1>
              <p class="mt-6 text-base leading-7 text-gray-600">Lo sentimos, no pudimos encontrar la página que estás buscando.</p>
              <div class="mt-10 flex items-center justify-center gap-x-6">
              <a href="/" class="rounded-md bg-azul px-3.5 py-2.5 text-sm font-medio uppercase text-white shadow-sm hover:bg-azul/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Regresar al Inicio</a>
              {/* <a href="#" class="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">&rarr;</span></a> */}
              </div>
          </div>
      </section>
    </Layout>
  )
}

export default ErrorPage