import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import { FaFacebookF, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";

import { Toaster, toast } from 'sonner';

import {
  IconButton,
} from "@material-tailwind/react";
import { TbRuler2Off } from 'react-icons/tb';

const Layout = ({children}) => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  }

  // useEffect(() => {
  //   toast.info('Necesitamos actualizar tu RFC', {
  //     action: {
  //       label: "Actualizar",
  //       onClick: () => window.location.href = "/cliente"
  //     },
  //   })
  // }, [])

  return (
    <>
        <Navbar />
        <Toaster richColors closeButton position="top-right"  />
        <main>
            {children}
        </main>
        <div className="fixed z-[999] bottom-5 right-5 speedDial">
          {/* <IconButton size="lg" onClick={handleOpen} className="rounded-full p-0 bg-azul flex justify-center items-center z-50 relative">
            <img src="/images/iso.svg" alt="" className='w-full' />
          </IconButton> */}
          <button className={`bg-azul border-none rounded-full transition-all flex justify-center items-center z-50 relative shadow-lg`} onClick={handleOpen}>
            <img src="/images/iso.svg" alt="" className='w-8/12' />
          </button>
          <button className={`bg-link border-none absolute ${open ? "bottom-[70px]" : "bottom-0"} rounded-full transition-all flex justify-center items-center z-40 scale-90`} onClick={() => window.open("https://m.me/VillarrealMx", "_blank")}>
            <FaFacebookF size={24} fill='white' />
          </button>
          <button className={`bg-rojo border-none absolute ${open ? "bottom-[140px]" : "bottom-0"} rounded-full transition-all flex justify-center items-center z-40 scale-90`} onClick={() => window.open("tel:8343182700", "_blank")}>
            <FaPhoneAlt size={24} fill='white' />
          </button>
          <button className={`bg-green-500 border-none absolute ${open ? "bottom-[210px]" : "bottom-0"} rounded-full transition-all flex justify-center items-center z-40 scale-90`} onClick={() => window.open("http://wa.me/528343110729", "_blank")}>
            <FaWhatsapp size={26} fill='white' />
          </button>
        </div>

        <Footer />
    </>
  )
}

export default Layout