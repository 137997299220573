import React from 'react'
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";

import { Toaster, toast } from 'sonner';

const Footer = () => {

    const handleSuscribete = () => {
        toast.success('¡Gracias por suscribirte!', {
            position: 'top-right',
            duration: 3000,
        })
    };

  return (
    <footer className='w-full bg-azul py-8 lg:py-16 px-[5vw] flex flex-col lg:flex-row justify-between items-start gap-0'>
        {/* <Toaster richColors closeButton position="top-right"  /> */}
        <div className="flex flex-col justify-start items-center lg:items-start gap-2 w-full lg:w-1/4 px-4 pt-8 lg:pt-0">
            <h3 className='text-white font-negro pb-6'>NOSOTROS</h3>
            <a href="/Empresa/QuienesSomos" className='text-white text-sm pb-2'>¿Quiénes somos?</a>
            <a href="/Empresa/Sucursales" className='text-white text-sm pb-2'>Nuestras sucursales</a>
            <a href="/Empresa/Sucursales" className='text-white text-sm pb-2'>Horarios</a>
            <a href="/Empresa/BolsaTrabajo" className='text-white text-sm pb-2'>Bolsa de Trabajo</a>
            <a href="/Empresa/Credito" className='text-white text-sm pb-2'>Crédito</a>
            <a href="/Empresa/Garantias" className='text-white text-sm pb-2'>Garantías</a>
        </div>
        <div className="flex flex-col justify-start items-center lg:items-start gap-2 w-full lg:w-1/4 px-4 pt-8 lg:pt-0">
            <h3 className='text-white font-negro pb-6'>AYUDA</h3>
            <a href="/Empresa/AvisodePrivacidad" className='text-white text-sm pb-2'>Aviso de privacidad</a>
            <a href="/Empresa/TerminosyCondiciones" className='text-white text-sm pb-2'>Términos y condiciones</a>
            <a href="/Empresa/PoliticasdeDevolucion" className='text-white text-sm pb-2'>Políticas de devolución</a>
            <a href="/Empresa/PreguntasFrecuentes" className='text-white text-sm pb-2'>Preguntas Frecuentes</a>
        </div>
        <div className="flex flex-col justify-start items-center lg:items-start gap-2 w-full lg:w-1/4 px-4 pt-8 lg:pt-0">
            <h3 className='text-white font-negro pb-6'>CONTACTO</h3>
            <a href="tel:8343182700" target='_blank' className='text-white text-sm pb-2 flex justify-start items-center gap-2'>
                <IoIosCall className='fill-white size-4' />
                (834) 318 2700
            </a>
            <a href="http://wa.me/528343110729" target='_blank' className='text-white text-sm pb-2 flex justify-start items-center gap-2'>
                <FaWhatsapp className='fill-white size-4' />
                (834) 311 0729
            </a>
            <a href="mailto:contacto@muebleria-villarreal.com" target='_blank' className='text-white text-sm pb-2 flex justify-start items-center gap-2'>
                <FiSend className='stroke-white size-4' />
                contacto@muebleria-villarreal.com
            </a>
            <br />
            <div className="flex flex-row w-full justify-center lg:justify-start items-center gap-3">
                <a href="https://www.facebook.com/VillarrealMx" target='_blank'><FaFacebook className='fill-white size-4' /></a>
                <a href="https://www.instagram.com/muebleria_villarreal/" target='_blank'><FaInstagram className='fill-white size-4' /></a>
                <a href="https://www.pinterest.com.mx/muelberiavillarrealcaballero/" target='_blank'><FaPinterest className='fill-white size-4' /></a>
                <a href="https://twitter.com/mueb_Villarreal" target='_blank'><FaXTwitter className='fill-white size-4' /></a>
                <a href="https://www.youtube.com/channel/UCGCntgo2owenNSPfDH-zejw/featured?disable_polymer=1" target='_blank'><FaYoutube className='fill-white size-4' /></a>
                <a href="https://www.tiktok.com/@muebleria.villarreal?lang=es" target='_blank'><FaTiktok className='fill-white size-4' /></a>
            </div>
            <br />
            <form target='_blank' noValidate action="https://gmail.us20.list-manage.com/subscribe/post?u=e8ed2c1a4fe5347be20e049f1&amp;id=eeea03237f" method="post" className='flex flex-col justify-start items-center lg:items-start w-full gap-2'>
                <h3 className='text-white font-negro pb-0'>SUSCRÍBETE</h3>
                <input type="mail" name="EMAIL" id="mce-EMAIL" className='w-full bg-white p-3 rounded-md text-dark text-sm' placeholder='Email' />
                <input type='submit' className='w-full bg-white text-center text-sm p-3 font-medio rounded-md' value="Suscríbete a nuestro Newsletter" name="subscribe" id="mc-embedded-subscribe"></input>
            </form>
        </div>
        <div className="flex flex-col justify-start items-center lg:items-end gap-2 w-full lg:w-1/4 px-4 pt-5 lg:pt-0">
            <img src='/images/lgosfooter.png' className='object-contain w-56' />
        </div>
    </footer>
  )
}

export default Footer