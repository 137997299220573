import Layout from '../../layout/Layout'

const Credito = () => {
  return (
    <Layout>
        <div className="container mx-auto bg-white p-5 shadow my-10 flex flex-col justify-start items-start gap-5">
            <h1 className='font-medio text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight'>Crédito</h1>
            <h2 className='text-base font-negro text-azul'>¿Cuales son los requisitos para obtener un crédito?</h2>
            <ul className='text-sm pl-5 list-disc'>
                <li>INE (Vigente)</li>
                <li>Comprobante de domicilio (de preferencia de agua no mayor a 3 meses de antiguedad)</li>
                <li>Comprobante de ingresos (nómina de los 2 últimas quincenas o estados de cuenta bancaria de los últimos 2 meses)</li>
            </ul>
            <p className='text-sm pl-5'>Los solicitantes a crédito deberán ser de nacionalidad mexicana.</p>
            <h2 className='text-base font-negro text-azul'>¿Dónde puedo realizar los pagos de mi mensualidad?</h2>
            <p className='text-sm pl-5'>Se puede realizar directamente en cualquiera de nuestras sucursales, deposito en banco u OXXO y transferencia bancaria.</p>
            <p className='text-sm pl-5'>Si realizas tu depósito a través de OXXO o transferencia bancaria, favor de enviar al numero de Whatsapp (834) 354 5696 lo siguiente:</p>
            <ul className='text-sm pl-5 list-disc list-inside'>
                <li>Foto del comprobante</li>
                <li>Nombre del titular de la cuenta</li>
                <li>Nombre del Articulo</li>
            </ul>
            <div className='flex flex-col lg:flex-row justify-between items-center lg:items-start gap-5 w-full max-w-4xl border-2 border-azul p-4 mx-auto'>
              <div className='flex flex-col justify-start items-center gap-2 flex-1'>
                <h3 className='text-azul font-negro text-base'>BANCO</h3>
                <img src="https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/Banco-img/Logo-ban4.png" alt="Logo Banorte" className='w-40' />
              </div>
              <div className='flex flex-col justify-start items-center gap-2 flex-1'>
                <h3 className='text-azul font-negro text-base'>CUENTA</h3>
                <p className='pt-3'>0021298239</p>
              </div>
              <div className='flex flex-col justify-start items-center gap-2 flex-1'>
                <h3 className='text-azul font-negro text-base'>CLABE</h3>
                <p className='pt-3'>072810000212982396</p>
              </div>
            </div>
            <p className='text-sm mx-auto font-negro'>Favor de reportar el pago al número de WhatsApp (834) 354 5696</p>
            <p className='text-sm pl-5'>Si no cuentas con número de Whatsapp, favor de comunicarse al (834) 318 2700 Ext-166 para mayor información.</p>
            <h2 className='text-base font-negro text-azul'>¿Dónde puedo conocer el estatus de mi cuenta?</h2>
            <p className='text-sm pl-5'>Para conocer el estatus de tu cuenta puedes comunicarte al número (834) 318 2700 Ext-110 o contactanos a través de nuestras redes sociales y nosotros te ayudaremos.</p>
        </div>
    </Layout>
  )
}

export default Credito