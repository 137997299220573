import { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { FaChevronRight } from "react-icons/fa";
import { BiSolidCart } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import {useLocation, useNavigate} from "react-router-dom";
import { TbLoader3 } from "react-icons/tb";
import { IoMdCloseCircle } from "react-icons/io";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { Toaster, toast } from 'sonner';

import {
    Accordion,
    AccordionHeader,
    AccordionBody,
    Dialog,
    DialogBody,
    Radio
} from "@material-tailwind/react";

import { Select, Option } from "@material-tailwind/react";

function Icon({ id, open }) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const Search = () => {

    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({
        searchRow: [],
        grupoRow: [],
        filtrosRow: [],
    });
    const [dataPopup, setDataPopup] = useState({});
    const [search, setSearch] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const [paginatedData, setPaginatedData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 24;
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setPaginatedData(data.searchRow.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage));
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const MAX_TEXT_LENGTH = 100; // Define la longitud máxima permitida

        let text = queryParams.get('text');
        if (text && text.length > MAX_TEXT_LENGTH) {
        text = text.substring(0, MAX_TEXT_LENGTH);
        }
        const orden = queryParams.get('orden');
        const color = queryParams.get('color');
        setSearch(text);
        setLoader(true);
        fetch("https://www.muebleria-villarreal.com/apimv/searchController.php?text=" + text + "&orden=" + orden + "&color=" + color)
        .then(response => response.json())
        .then(data => {
            setData(data);
            setLoader(false);

            setPaginatedData(data.searchRow ? data.searchRow.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) : []);
            setTotalPages(Math.ceil((data.searchRow ? data.searchRow.length : 0) / itemsPerPage));
        })
        .catch(error => {
            console.log(error);
            setLoader(false);
        });
    }, [location.search]);

    const handleOrden = (e) => {
        const newValue = e;
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
    
        if (params.has('orden')) {
            params.set('orden', newValue);
        } else {
            params.append('orden', newValue);
        }
    
        navigate({
            pathname: url.pathname,
            search: params.toString()
        });
    };

    const handlePrecio = (min, max) => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
    
        if (params.has('precioMin')) {
            params.set('precioMin', min);
        } else {
            params.append('precioMin', min);
        }
    
        if (params.has('precioMax')) {
            params.set('precioMax', max);
        } else {
            params.append('precioMax', max);
        }
    
        navigate({
            pathname: url.pathname,
            search: params.toString()
        });
    };

    const handleColor = (color) => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
    
        if (params.has('color')) {
            params.set('color', color);
        } else {
            params.append('color', color);
        }
    
        navigate({
            pathname: url.pathname,
            search: params.toString()
        });
    }

    const deletePrecio = (precio) => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
    
        if (params.has('precioMin')) {
            params.delete('precioMin');
        }
    
        if (params.has('precioMax')) {
            params.delete('precioMax');
        }
    
        navigate({
            pathname: url.pathname,
            search: params.toString()
        });
    }

    const deleteColor = (color) => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
    
        if (params.has('color')) {
            params.delete('color');
        }
    
        navigate({
            pathname: url.pathname,
            search: params.toString()
        });
    }

    const [openModal, setOpenModal] = useState(false);
    const [qty, setQty] = useState(1);

    const handleOpenModal = (dataProd) => {
        setDataPopup(dataProd);
        setOpenModal(!openModal);
        setQty(1);
    };
    
    const agregarCarrito = () => {
        setOpenModal(!openModal);
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        let inventario = Math.floor(dataPopup.exist);
    
        const productIndex = cart.findIndex(item => item.articulo === dataPopup.articulo);
    
        if (productIndex !== -1) {
            let cantidad = cart[productIndex].qty + qty;
            if(cantidad > inventario) {
                cantidad = inventario;
                // notifyInventario(inventario);
                toast.error('Solamente contamos con ' + inventario + ' articulos en el inventario.', {
                    duration: 3000
                })
            } else {
                // notify();
                toast.success('Articulo Agregado', {
                    duration: 3000
                })
            }
            cart[productIndex].qty = cantidad;
        } else {
            cart.push({
                articulo: dataPopup.articulo,
                qty: qty
            });
            toast.success('Articulo Agregado', {
                duration: 3000
            })
        }
    
        localStorage.setItem('cart', JSON.stringify(cart));
        window.dispatchEvent(new Event('cartUpdated'));
    }

    const [open, setOpen] = useState(null);

    const handleOpen = (value) => setOpen(open === value ? null : value);

  return (
    <Layout>
        {/* <Toaster richColors closeButton position="top-right"  /> */}
        <Dialog size='lg' open={openModal} handler={handleOpenModal}>
            <DialogBody className='max-h-[80lvh] overflow-y-auto'>
                <div className="flex flex-col lg:flex-row justify-between items-center gap-5">
                <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + dataPopup.departamento + "/" + dataPopup.articulo + ".jpg?quality=auto&format=webp"} alt="" className={`h-48 w-full lg:h-full lg:aspect-square lg:w-5/12 object-contain rounded-lg`} />
                    <div className="flex flex-col justify-start items-start w-full lg:w-6/12 gap-2">
                        <h1 className='text-xl lg:text-2xl font-negro'>{dataPopup.descripcion_corta}</h1>
                        <span className='text-sm text-gris font-medio'>Cod: {dataPopup.articulo}</span>
                        <div className="flex flex-row justify-start items-end gap-2 py-2 w-full">
                            <span className='text-gris text-sm cross line-through font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dataPopup.precio_lista)}</span>
                            <span className='text-rojo text-2xl lg:text-3xl cross font-negro'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dataPopup.precio_contado)}</span>
                        </div>
                        <span className='text-sm text-green-600 font-medio'>Producto disponible</span>
                        <div className="flex mt-4 flex-row justify-between lg:justify-start items-center border border-azul p-2 rounded-md mb-5 w-full lg:w-fit">
                            <button className='text-dark font-negro aspect-square w-7' onClick={() => setQty(prevQty => Math.max(1, prevQty - 1))}>-</button>
                            <input type="text" className=' border-0 appearance-none text-dark text-center w-24 outline-none' min={1} value={qty}
                                onKeyDown={(e) => {
                                    if (e.key === '.' || e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                onFocus={() => {
                                    setQty("")
                                }}
                                onChange={(e) => {
                                    let value = e.target.value.trim(); // Trim the input value
                                    // Allow the value to be an empty string
                                    if (value === "") {
                                        setQty("");
                                    } else if (isNaN(value)) {
                                        setQty(1);
                                    } else {
                                        // Ensure the value is a number
                                        value = parseInt(value, 10);
                                        // Convert the value to a number
                                        value = Number(value);
                                        // Ensure the value is within the valid range
                                        value = Math.max(1, Math.min(dataPopup.exist, value));
                                        setQty(value);
                                    }
                                }}
                                onBlur={(e) => {
                                    // Re-validate the input on blur
                                    let value = e.target.value;
                                    if (isNaN(value)) {
                                        value = 1;
                                    } else if(value == 0) {
                                        value = 1;
                                    } else {
                                        value = parseInt(value, 10);
                                    }
                                    value = Number(value);
                                    value = Math.max(1, Math.min(dataPopup.exist, value));
                                    setQty(value);
                                }}
                            />
                            <button className='text-dark font-negro aspect-square w-7' onClick={() => setQty(prevQty => Math.min(dataPopup.exist, prevQty + 1))}>+</button>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-start items-center gap-2 w-full">
                            <button className='w-full lg:w-fit bg-azul py-2 px-3 text-white text-sm rounded flex justify-center items-center gap-2' onClick={agregarCarrito}><BiSolidCart fill='white' size={20} />Agregar a Carrito</button>
                            <a href={"/Articulo/" + dataPopup.articulo + "/" + dataPopup.grupo} className='w-full lg:w-fit bg-link py-2 px-3 text-white text-sm rounded flex justify-center items-center gap-2'><FaEye fill='white' size={18} />Ver</a>
                        </div>
                    </div>
                </div>
            </DialogBody>
        </Dialog>
        <section className="w-full px-[5vw] py-10 lg:py-14 relative">
            {
                loader &&
                    <div className="w-full h-full flex justify-center items-start p-10 absolute top-0 left-0 z-20 bg-white/90">
                        <TbLoader3 className='animate-spin stroke-azul' size={50} />
                    </div>
            }
            <div className="flex flex-row justify-start items-center gap-1">
                <a href="/" className='text-azul font-negro'>Inicio</a>
                <FaChevronRight size={10} />
                <p>{search}</p>
            </div>
            <div className="flex flex-col lg:flex-row w-full justify-between items-start gap-10 pt-8 relative">
                <aside className='w-full lg:w-4/12 xl:w-3/12 flex flex-col justify-start items-start relative'>
                    <h2 className='font-medio text-xl lg:text-2xl text-left border-b border-b-[#cfcfcf] w-full pb-3 tracking-tight mb-5'>Filtrar por:</h2>
                    <div className='w-full flex flex-row flex-wrap justify-start items-center gap-2'>
                        {
                            queryParams.get('color') &&
                            <span className='text-xs bg-white border border-gray-300 py-1.5 px-3 rounded-xl transition-all hover:bg-gray-300 cursor-pointer flex justify-start items-center gap-1 uppercase' onClick={() => deleteColor(queryParams.get('color'))}><IoMdCloseCircle className='fill-dark' size={14} />{queryParams.get('color')}</span>
                        }
                    </div>
                    <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                        <AccordionHeader className='text-sm border-0 text-dark font-medio py-4' onClick={() => handleOpen(1)}>Color</AccordionHeader>
                        <AccordionBody className="py-1">
                            <div className='flex flex-row flex-wrap w-full justify-start items-center gap-2'>
                                <div title="Chocolate" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Chocolate')} className="bg-[#45322e] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Blanco"  data-toggle="tooltip" data-placement="top" onClick={() => handleColor('Blanco')} className="bg-white border border-gray-400 size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Rojo" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Rojo')} className="bg-[#C0392B] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Azul" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Azul')} className="bg-[#317CF0] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Amarillo" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Amarillo')} className="bg-[#FECC33] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Verde" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Verde')} className="bg-[#FECC33] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Rosa" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Rosa')} className="bg-[#F5A8DC] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Naranja" data-toggle="tooltip" data-placement="top" onClick={() => handleColor('Naranja')} className="bg-[#FF5733] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Beige" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Beige')} className="bg-[#E8C39E] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Morado" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Morado')} className="bg-[#652F71] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Gris" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Gris')} className="bg-[#9E9B9B] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Negro" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Negro')} className="bg-black size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Nogal" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Nogal')} className="bg-[#804000] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Nogal Oscuro" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Nogal Oscuro')} className="bg-[#4B3621]  size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Perla" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Perla')} className="bg-[#EAE6CA]  size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Plata" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Plata')} className="bg-gradient-to-r from-[#eef2f3] to-[#8e9eab]  size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Dorado" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Dorado')} className="bg-gradient-to-r from-amber-200 to-yellow-500  size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Tabaco" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Tabaco')} className="bg-[#6d5e4d] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Cafe" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Cafe')} className="bg-[#642800] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Bronce" data-toggle="tooltip" data-placement="top"  onClick={() => handleColor('Bronce')} className="bg-[#cd7f32] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Menta" data-toggle="tooltip" data-placement="top" onClick={() => handleColor('Menta')} className="bg-[#20603d] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Vino" data-toggle="tooltip" data-placement="top" onClick={() => handleColor('Vino')} className="bg-[#56070C] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                                <div title="Celeste" data-toggle="tooltip" data-placement="top" onClick={() => handleColor('Celeste')} className="bg-[#0CB7F2] size-7 transition-all hover:border hover:border-gray-500 cursor-pointer shadow rounded-full"></div>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
                        <AccordionHeader className='text-sm border-0 text-dark font-medio py-4' onClick={() => handleOpen(6)}>Precio</AccordionHeader>
                        <AccordionBody className="py-1">
                            <ul className='gap-0'>
                                <li className='text-sm font-normal radioCheck'>
                                    <Radio name="precioRadio" className="border-gray-900/10 bg-gray-900/5 p-0 transition-all hover:before:opacity-0" onChange={() => deletePrecio(0) } label={<span>Todos</span>} />
                                </li>
                                <li className='text-sm font-normal radioCheck'>
                                    <Radio name="precioRadio" className="border-gray-900/10 bg-gray-900/5 p-0 transition-all hover:before:opacity-0" onChange={() => handlePrecio(1,3000) } label={<span>$0 a $3,000</span>} />
                                </li>
                                <li className='text-sm font-normal radioCheck'>
                                    <Radio name="precioRadio" className="border-gray-900/10 bg-gray-900/5 p-0 transition-all hover:before:opacity-0" onChange={() => handlePrecio(3000,5000) } label={<span>$3,000 a $5,000</span>} />
                                </li>
                                <li className='text-sm font-normal radioCheck'>
                                    <Radio name="precioRadio" className="border-gray-900/10 bg-gray-900/5 p-0 transition-all hover:before:opacity-0" onChange={() => handlePrecio(5000,10000) } label={<span>$5,000 a $10,000</span>} />
                                </li>
                                <li className='text-sm font-normal radioCheck'>
                                    <Radio name="precioRadio" className="border-gray-900/10 bg-gray-900/5 p-0 transition-all hover:before:opacity-0" onChange={() => handlePrecio(10000,15000) } label={<span>$10,000 a $15,000</span>} />
                                </li>
                                <li className='text-sm font-normal radioCheck'>
                                    <Radio name="precioRadio" className="border-gray-900/10 bg-gray-900/5 p-0 transition-all hover:before:opacity-0" onChange={() => handlePrecio(15000,20000) } label={<span>$15,000 a $20,000</span>} />
                                </li>
                                <li className='text-sm font-normal radioCheck'>
                                    <Radio name="precioRadio" className="border-gray-900/10 bg-gray-900/5 p-0 transition-all hover:before:opacity-0" onChange={() => handlePrecio(20000,30000) } label={<span>$20,000 a $30,000</span>} />
                                </li>
                                <li className='text-sm font-normal radioCheck'>
                                    <Radio name="precioRadio" className="border-gray-900/10 bg-gray-900/5 p-0 transition-all hover:before:opacity-0" onChange={() => handlePrecio(30000,100000) } label={<span>$30,000 a $100,000</span>} />
                                </li>
                            </ul>
                        </AccordionBody>
                    </Accordion>
                </aside>
                <div className='w-full flex flex-col justify-start items-start gap-5'>
                    <div className='w-full lg:max-w-80'>
                        <Select label="Ordenar por" onChange={(e) => handleOrden(e)}>
                            <Option value="preestablecido">Preestablecido</Option>
                            <Option value="asc">Menor precio</Option>
                            <Option value="desc">Mayor precio</Option>
                            <Option value="nuev">Nuevos</Option>
                            <Option value="vend">Más vendidos</Option>
                        </Select>
                    </div>
                    <div className="grid grid-cols-2 xl:grid-cols-3 gap-5 w-full relative">
                        {
                            paginatedData.length > 0 ? paginatedData.map((articulo, index) => (
                                <div key={articulo.articulo} className='relative w-full flex flex-col justify-start items-start gap-2 p-3 z-10 bg-white rounded-lg shadow transition-all hover:scale-105 hover:shadow-xl hover:z-30'>
                                    <a href={"/Articulo/" + articulo.articulo + "/" + articulo.grupo} className='relative w-full flex flex-col justify-start items-start gap-2 p-3 bg-white'>
                                        <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + articulo.departamento + "/" + articulo.articulo + ".jpg?quality=auto&format=webp"} alt="" className='w-full object-cover rounded-lg aspect-square' />
                                        <h5 className='text-center lg:text-left font-negro leading-tight pt-2 text-sm lg:text-lg'>{articulo.descripcion_corta}</h5>
                                        <div className="flex flex-col lg:flex-row justify-start items-center lg:items-end lg:gap-2 pt-0 w-full">
                                            <span className='text-gris text-xs lg:text-sm cross line-through font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(articulo.precio_lista)}</span>
                                            <span className='text-rojo lg:text-xl cross font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(articulo.precio_contado)}</span>
                                        </div>
                                    </a>
                                    <button className='text-xs lg:text-sm text-white text-center p-2 w-full bg-azul rounded-md relative z-10 mt-auto' onClick={() => handleOpenModal(articulo)}>
                                        Agregar
                                    </button>
                                </div>
                            ))
                            :
                            !loader ?
                                <div className='relative w-full flex flex-col justify-start items-center gap-1 p-4 bg-white rounded-lg column col-span-3'>
                                    <div className='size-20 rounded-full bg-azul flex justify-center items-center '>
                                        <img src="/images/iso.svg" className='w-7/12 pt-1' />
                                    </div>
                                    <h3 className='text-lg lg:text-3xl font-medio text-azul text-center pt-5'>No se encontró ningun producto</h3>
                                    <p className='text-base text-center'>Favor de intentar nuevamente</p>
                                </div>
                            :
                            null
                        }
                    </div>
                    
                    {
                        totalPages !== 0 &&
                            <div className='pagination flex flex-row justify-center items-center gap-2 w-full pt-5'>
                                <button 
                                    onClick={() => handlePageChange(currentPage - 1)} 
                                    className={`size-8 flex justify-center items-center border border-azul rounded page-button font-medio transition-all ${currentPage === 1 ? 'opacity-50 ' : 'hover:bg-azul hover:text-white group'}`} 
                                    disabled={currentPage === 1}
                                >
                                    <IoIosArrowBack size={20} className='fill-azul group-hover:fill-white transition-all' />
                                </button>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <button key={index} onClick={() => handlePageChange(index + 1)} className={`size-8 flex justify-center items-center border border-azul rounded hover:bg-azul hover:text-white page-button font-medio transition-all ${currentPage === index + 1 ? 'bg-azul text-white' : 'text-azul'}`}>
                                        {index + 1}
                                    </button>
                                ))}
                                <button 
                                    onClick={() => handlePageChange(currentPage + 1)} 
                                    className={`size-8 flex justify-center items-center border border-azul rounded page-button font-medio transition-all ${currentPage === totalPages ? 'opacity-50' : 'hover:bg-azul hover:text-white group'}`} 
                                    disabled={currentPage === totalPages}
                                >
                                    <IoIosArrowForward size={20} className='fill-azul group-hover:fill-white transition-all' />
                                </button>
                            </div>
                    }
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default Search