import Layout from '../../layout/Layout'

const QuienesSomos = () => {
  return (
    <Layout>
        <div className="container mx-auto bg-white p-5 shadow my-10 flex flex-col justify-start items-start gap-5">
            <h1 className='font-medio text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight'>Sobre Nosotros</h1>
            <h2 className='text-base font-negro text-azul'>FILOSOFÍA DE TRABAJO</h2>
            <p className='text-sm pl-5'>Requerimos que nuestra Filosofía de trabajo se viva y se palpe en todas nuestras Unidades de negocios y que permeen a todos los puestos y niveles de la organización.</p>
            <h2 className='text-base font-negro text-azul'>VISIÓN</h2>
            <p className='text-sm pl-5'>Ser la mueblería líder en el mercado regional ofreciendo la más variada gama de productos para el hogar, equipo electrónico y artículos decorativos, en las mejores condiciones de contado y crédito a través de un equipo de colaboradores capacitados y enfocados en el servicio al cliente.</p>
            <h2 className='text-base font-negro text-azul'>MISIÓN</h2>
            <p className='text-sm pl-5'>Satisfacer a nuestros clientes brindándole las mejores soluciones en todo tipo de muebles para el hogar, equipo electrónico y artículos decorativos, apoyados por un equipo de trabajo profesional, amable y responsable.</p>
            <h2 className='text-base font-negro text-azul'>COMPROMISO</h2>
            <p className='text-sm pl-5'>Ofrecer a nuestros clientes la más variada gama de productos para el hogar, equipo electrónico y artículos decorativos para el hogar con la mejor atención y servicio ofreciendo los precios mas competitivos del mercado.</p>
            <h2 className='text-base font-negro text-azul'>PRINCIPIOS</h2>
            <p className='text-sm pl-5'>Nuestra empresa debe ser <b className='text-azul'>Honesta, Eficiente, Productiva y Enfocada</b> a la <b className='text-azul'>CALIDAD</b> del servicio al cliente.</p>
            <p className='text-sm pl-5'><b>Compromiso</b>.- Es dar el mejor servicio a nuestros clientes con una</p>
            <p className='text-sm pl-5'><b>Actitud</b>.- Pro positiva y de trato amable y que por medio de nuestros</p>
            <p className='text-sm pl-5'><b>Liderazgo</b>.- Llevemos a la empresa a lograr su Visión y Misión con</p>
            <p className='text-sm pl-5'><b>Integridad</b>.- Y honradez de nuestro personal encaminándolos a su</p>
            <p className='text-sm pl-5'><b>Desarrollo</b>.- Dentro de la organización consiguiendo su</p>
            <p className='text-sm pl-5'><b>Apoyo</b>.- Y que mediante una metodología de Trabajo en Equipo las</p>
            <p className='text-sm pl-5'><b>Decisiones</b>.- Se tomen para beneficio de la Empresa.</p>
        </div>
    </Layout>
  )
}

export default QuienesSomos