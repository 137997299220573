import React, { useState } from 'react'
import Layout from '../../layout/Layout'

const TerminosCondiciones = () => {

  return (
    <Layout>
        <div className="container mx-auto pt-5 pb-10 lg:pb-20 px-4 flex flex-col lg:flex-row justify-between items-start gap-0">
            <aside className='flex flex-col justify-start items-start gap-3 w-full lg:w-3/12 bg-white shadow rounded p-5'>
                <h1 className='font-medio text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight'>Términos y condiciones</h1>
                <ul className="pl-5">
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#tarjetaCD"> Tarjetas de crédito / débito </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#preciosAL"> Precios anunciados en línea </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#precios"> Precios </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#promociones"> Promociones </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#productos"> Productos </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#cancelacionMRD"> Cancelación de mercancía </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#cancelacionME"> Cancelaciones cuando la mercancía se entregó </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#cancelacionP"> Cancelación de pedido </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#procesoR"> Proceso de reembolso </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#pagoEA"> Pago en efectivo a través de establecimientos autorizados </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#facturacion"> Facturación </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#pagosAC"> Pago o abono a cuenta de cliente </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#envioG"> Enviós Gratis </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#procesoE"> Proceso de envío </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#entregas"> Entregas </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#pagosR"> Pagos y registros </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#seguridadS"> Seguridad del sitio </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#derechoP"> Derecho de propiedad </a> </li>
                    <li className='mt-3 pl-2'> <a className='underline text-azul' href="#garantiaP"> Garantía del producto </a> </li>
                </ul>
            </aside>
            <div className='flex flex-col justify-start items-start gap-3 w-full lg:w-9/12 py-10 lg:py-5 px-4 lg:px-10 pt-10'>
                <h1 id='tarjetaCD' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 font-bold text-azul'>Tarjetas de crédito / débito</h1>
                <p className='pb-3'>Al momento de realizar el pago total de tu compra, puedes pagar con tarjeta de crédito o débito. En el momento que se autorice tu compra, cualquier duda, queja y aclaración te sugerimos contactar a tu banco, ya que ellos son los que te podrán ayudar a solucionarlo.
                El precio total de tu compra se te informará antes de confirmar.</p>

                <h1 id='preciosAL' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Precios anunciados en línea</h1>
                <p className='pb-3'>Todos los precios anunciados en el portal www.muebleria-villarreal.com son precios de riguroso contado y son exclusivos para venta en línea, sujetos a la promoción del mes. Todos nuestros precios publicados se encuentran en moneda nacional (peso mexicano) y con I.V.A. incluido. Si deseas una cotización personalizada con precio crédito, envíanos un whatsapp al (834)311 0729 y con gusto te atenderemos.</p>

                <h1 id='precios' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Precios</h1>
                <p className='pb-3'>Los precios, promociones y vigencias se encuentran sujetos a cambio sin previo aviso y están sujetos a existencias. Mueblería Villarreal Caballero S.A. de C.V. se reserva el derecho de hacer cambios a estas páginas y/o exclusiones, términos y condiciones en cualquier momento. Ofertas anunciadas en el sitio web www.muebleria-villarreal.com y en redes sociales tienen vigencia solo el día de su publicación, o hasta agotar existencias. Precios en moneda nacional (peso mexicano) y con I.V.A. incluido.</p>
                <p className='pb-3'>Si se llega a presentar un error en el precio de uno de nuestros artículos en nuestra página web, Mueblería Villarreal tendrá el derecho de cancelar los pedidos que incluyan este artículo. Mueblería Villarreal se compromete a notificar al cliente vía correo, teléfono o whatsapp.</p>

                <h1 id='promociones' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Promociones</h1>
                <p className='pb-3'>Todas las promociones son válidas por tiempo limitado o hasta agotar existencias. Solo aplican en modelos seleccionados.</p>

                <h1 id='productos' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Productos</h1>
                <p className='pb-3'>Algunas fotografías son sólo de carácter ilustrativo. Los productos y sus características pueden variar a las fotografías y datos presentados según especificaciones del fabricante. En algunos casos el producto no se ilustra en esta página o en el catálogo publicado.</p>
                <p className='pb-3'>Las recámaras no incluyen base ni colchon.</p>
                <p className='pb-3'>Cada uno de nuestros muebles es único, por tal motivo pueden existir variaciones en las texturas, tonos y acabados.</p>
                <p className='pb-3'>Aplican restricciones.</p>

                <h1 id='cancelacionMRD' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Cancelación de mercancía</h1>
                <p className='pb-3'>1. Comunicarse con Mueblería Villarreal al (834) 318 2700 para solicitar que no se entregue la mercancía.</p>
                <p className='pb-3'>2. Acudir personalmente a la sucursal para solicitar la autorización de cancelación a gerencia.</p>
                <p className='pb-3'>3. Una vez teniendo la autorización de la cancelación, el monto correspondiente se tomará a favor para la compra de un nuevo producto en la tienda. Si hay una diferencia de precio, el cliente tendrá que cubrir el monto restante. En caso de ser un monto más pequeño, el cliente tendrá un saldo a su favor y se le entregará un vale con vigencia de 1 año para futuras compras y/o abonos.</p>
                <p className='pb-3'>Si la compra fue a crédito, la diferencia será sobre el enganche. y si fue a contado, será sobre el total del valor de la mercancía.</p>

                <h1 id='cancelacionME' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Cancelaciones cuando la mercancía se entregó</h1>
                <p className='pb-3'>1. Acudir personalmente a la sucursal para solicitar la autorización de cancelación a gerencia.</p>
                <p className='pb-3'>2. Tras la autorización de la cancelación, el departamento de garantías se encargará de revisar el estado de la mercancía, si esta se encuentra en perfectas condiciones de funcionamiento y sin daños evidentes por mal uso, nuestro personal la recibirá.</p>
                <p className='pb-3'>3. Una vez teniendo la mercancía en cualquiera de nuestras sucursales, el monto correspondiente se tomará a favor para la compra de un nuevo producto en la tienda. Si hay una diferencia de precio, el cliente tendrá que cubrir el monto restante. En caso de ser un monto más pequeño, el cliente tendrá un saldo a su favor y se le entregará un vale con vigencia de 1 año para futuras compras y/o abonos.</p>
                <p className='pb-3'>Si la compra fue a crédito, la diferencia será sobre el enganche. y si fue a contado, será sobre el total del valor de la mercancía.</p>

                <h1 id='cancelacionP' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Cancelación de pedido</h1>
                <p className='pb-3'>Después de realizar una evaluación interna, Mueblería Villarreal se reserva el derecho de realizar cancelaciones de pedidos donde el cliente no proporcione la información requerida, no haya disponibilidad en inventario, entregas fuera de nuestras rutas o sospecha de fraude.</p>

                <h1 id='procesoR' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Proceso de reembolso</h1>
                <p className='pb-3'>Cuando un cliente cancela su mercancía por diferentes causas y no quiere el vale para futuras compras y/o abonos y desea que se le regrese su dinero:</p>
                <p className='pb-3'>1. Se tendrá que solicitar autorización de gerencia.</p>
                <p className='pb-3'>2. Ya teniendo la autorización, el departamento de contabilidad iniciará con el trámite, ya sea para cheque o transferencia.</p>
                <p className='pb-3'>3. Si se tramita un cheque, se le notificará al cliente en cuanto esté listo para que el cliente pase a recogerlo.</p>
                <p className='pb-3'>Este proceso es interno tanto la autorización como el formato de la solicitud, el cliente no tendrá que realizarlo, solo tendrá que solicitarlo y esperar a que se autorice.</p>
                <p className='pb-3'>No hay tiempo definido para llevar a cabo, el tiempo puede variar.</p>

                <h1 id='pagoEA' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Pago en efectivo a través de establecimientos autorizados</h1>
                <p className='pb-3'>Por el momento solo se pueden realizar compras de riguroso contado a través de nuestra página web con tarjeta de débito o crédito, no estamos aceptando pagos en otros establecimientos.</p>

                <h1 id='facturacion' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Facturación</h1>
                <p>Al momento de realizar el proceso de compra, en el paso 2 podrás solicitar tu factura, llenando la siguiente información:</p>
                <p>- RFC (Registro Federal de Contribuyentes)</p>
                <p>- Nombre (persona física) / Razón Social (persona moral)</p>
                <p>- Domicilio Fiscal</p>
                <p>- Correo Electrónico</p>
                <p>-Uso de CFDI</p>
                <p>La factura será enviada a su correo electrónico, en caso de no recibirla dentro de las próximas 24 horas, favor de comunicarse vía facebook. Es responsabilidad del cliente revisar que los datos e información estén correctos. En caso de necesitar ayuda, favor de comunicarse al (834) 318 2700 para mayor información.</p>
                
                <h1 id='pagosAC' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Pago o abono a cuenta de cliente</h1>
                <p>Se puede realizar directamente en cualquiera de nuestras sucursales, deposito en banco u OXXO y transferencia bancaria.</p>
                <p>Si realizas tu depósito a través de OXXO o transferencia bancaria, favor de enviar al numero de Whatsapp (834) 148 9747 lo siguiente:</p>
                <p>- Foto del comprobante</p>
                <p>- Nombre del titular de la cuenta</p>
                <p>o</p>
                <p>Si no cuentas con número de Whatsapp, favor de comunicarse al (834) 318 2700 Ext-160 para mayor información.</p>
                <p>En compras que realices en nuestro sitio podrás hacer tus pagos a través de una transferencia electrónica por Internet o de la banca móvil.</p>
                <p>Durante el proceso de compra se te informará la cuenta CLABE para realizar el pago correspondiente por este método.</p>
                
                <h1 id='envioG' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Envió Gratis</h1>
                <p>Todas las compras realizadas a través de nuestra página web www.muebleria-villarreal.com cuentan con envío gratuito a los municipios de Cd Victoria y Matamoros, Tamaulipas.</p>
                
                <h1 id='procesoE' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Proceso de envío</h1>
                <p>Para la realización de envío del o los productos adquiridos a través de nuestra página web, uno de nuestros asesores comerciales se pondrá en contacto con el cliente al momento de concluir su compra para determinar la fecha del envío. En caso de que el producto adquirido genere algún costo de envío, se le informará al cliente durante el proceso de compra, si tienes alguna duda contactanos al 834 31 82700 Ext 157 y con gusto te atenderemos.</p>
                <p>Es importante que se proporcione la dirección completa de destino y un número telefónico para evitar retrasos de entrega. Para entregar la mercancía es necesario que en el domicilio de entrega se encuentre alguien mayor de edad para recibirla.</p>
                <p>En dado caso de que no se concrete la entrega, habrá que checar disponibilidad y reprogramar la fecha de entrega, teniendo un costo adicional de $150 pesos mexicanos.</p>
                
                <h1 id='entregas' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Entregas</h1>
                <p>Mueblería Villarreal no se hace responsable de maniobras especiales como desmontar puertas o ventanas, uso de elevadores, subir el producto a determinado nivel de piso, mover muebles o decoraciones, volados, instalaciones eléctricas, entre otros. El personal que realiza la entrega no está autorizado para realizar nada de lo anteriormente mencionado.</p>
                <p>Al momento de recibir la entrega, favor de verificar que esté en buen estado y firmar el formato de recibido.</p>
                
                <h1 id='pagosR' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Pagos y registros</h1>
                <p>Una vez que hayas escogido todos los artículos que vas a comprar, puedes escoger una de las siguientes 3 formas para concluir tu compra:</p>
                <p>1. Inicia sesión creando una cuenta.</p>
                <p>Para registrarte necesitarás:</p>
                <p>-Nombre y apellido</p>
                <p>-Fecha de nacimiento</p>
                <p>-Correo</p>
                <p>-Contraseña</p>
                <p>2. Inicia sesión a través de facebook</p>
                <p>Para registrarte necesitarás:</p>
                <p>-Correo electrónico y contraseña de usuario de facebook</p>
                <p>3. Realizar compra como invitado</p>
                <p>Necesitarás la siguiente información:</p>
                <p>-Datos del cliente</p>
                <p>-Domicilio de entrega</p>
                <p>Ya estando dentro del portal, deberás seleccionar el método de pago con el cual realizarás la compra, los cuales podrán ser tarjeta de débito y/o crédito, efectivo o transferencia.</p>

                <h1 id='seguridadS' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Seguridad del sitio</h1>
                <p>Toda la información que nos proporciones de principio a fin de tu compra es completamente confidencial, tu información de pago está protegida con los más estrictos estándares de confidencialidad y seguridad.</p>

                <h1 id='derechoP' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Derecho de propiedad</h1>
                <p>Todo lo contenido bajo el dominio de www.muebleria-villarreal.com, tanto el texto como las gráficas, logos, íconos, imágenes, audio clips y software, son propiedad de Mueblería Villarreal Caballero, S.A. de C.V. y se encuentran protegidos sus derechos por la Ley de Fomento y Protección de la Propiedad Industrial, y por los tratados internacionales. La recopilación de todo el contenido ya sea gráfico o textual en esta página está protegidas por los derechos de a que pueden ser propias de Mueblería Villarreal Caballero, S.A. de C.V. o Subsidiarias o filiales de sus respectivos anunciantes. Se prohíbe el uso de reproducción, modificación, distribución, transmisión, re publicación, exhibición o ejecución del contenido de esta página sin autorización por escrito de la empresa.</p>

                <h1 id='garantiaP' className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Garantía del producto</h1>
                <p>Todos nuestros productos cuentan con garantía contra defectos de fabricación (las garantías de cada artículo varían dependiendo del fabricante). Si la mercancía sufre un daño durante el envío realizado por nuestra misma empresa, Mueblería Villarreal es el encargado de resolver el problema. Es responsabilidad del cliente revisar la mercancía cuando se le entregue, en caso de ver algún defecto, es necesario mencionarlo al personal para realizar el cambio.</p>
                <p>Línea blanca y electrónica.- Cuentan con 1 Año de Garantía en todos sus componentes sin costo alguno, es importante recalcar que la marca es quien se encarga de brindar este servicio ya que Mueblería Villarreal solo es distribuidor de sus productos. Por lo que cualquier situación que se presente por defectos de fabricación en el funcionamiento de los equipos, así como la autorización de un cambio físico de los mismos serán exclusivamente atendidos por la marca.</p>
                <p>Muebles.- Los productos nacionales cuentan con 6 meses de garantía. Los productos de importación manejan un tiempo de 3 meses en garantía. Solo aplica cuando el producto cuente con defecto de fabricación.</p>
                <p>Colchonería.- Todos los colchones cuentan con garantía por defecto de fábrica el primer año, cada marca maneja tiempos de garantía diferentes para cada modelo. Es importante revisar la póliza del o los colchones adquiridos, ya que todas las fábricas hacen un cargo adicional por el tiempo que ya tiene de uso el colchón. Las garantías de los colchones son responsabilidad directa del fabricante.</p>
                <p>Por razones de sanidad e higiene, no se hacen cambios de colchones, al momento de recibirlo en su domicilio, es responsabilidad del cliente revisar el estado del colchón al retirar el plástico protector que lo protege.</p>
                <p>Mueblería Villarreal no se hace responsable de garantías expiradas, tampoco se hará responsable si el cliente hizo mal uso del producto, presenten reparaciones efectuadas por un tercero o hayan sido trasladados a otro lugar del domicilio donde se entregó.</p>
            
                <h1 className='text-xl lg:text-2xl text-left border-l border-l-azul pl-5 tracking-tight scroll-m-5 mb-4 mt-10 font-bold text-azul'>Condiciones de servicio al tratamiento de datos personales</h1>
                <p>Le hacemos saber, que la información personal que usted nos proporcione, será utilizada para proveer de nuestros servicios, productos, promociones, concursos, y encuestas de satisfacción que comercializamos, así como también informarle sobre cambios en los mismos, por medio de mensajes de texto, correos electrónicos, llamadas telefónicas.</p>
            </div>
        </div>
    </Layout>
  )
}

export default TerminosCondiciones