import React, {useState,useEffect} from 'react'
import Layout from '../../layout/Layout'

const MesaRegalosSearch = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
      evento: [],
      results: []
  });

    useEffect(() => {
        fetch('https://www.muebleria-villarreal.com/apimv/mesaregalosNombreController.php?nombre=MAYRA&paterno=BENAVIDES')
        .then(response => response.json())
        .then(data => {
          setData(data)
          if (data.evento.length === 0) {
              window.location.href = '/mesaregalos'
          }
          setTimeout(() => {
              setLoading(false);
          }, 500);
          console.log('Response data:', data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }, []);
  return (
    <Layout>

    </Layout>
  )
}

export default MesaRegalosSearch