import React, { useState } from 'react'
import Layout from '../../layout/Layout'
import { FaShareAltSquare } from "react-icons/fa";
import { FaGifts } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";

import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Checkbox,
    Radio,
} from "@material-tailwind/react";

const MesaRegalosIndex = () => {

    const [open, setOpen] = useState(false);
    const [select, setSelect] = useState("numero");

    const [nombre, setNombre] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [fechaEvento, setFechaEvento] = useState('');

    const [numEvento, setNumEvento] = useState('');

    const handleOpen = () => {
        if(open){
            setSelect("numero");
            setNombre('');
            setApellidoPaterno('');
            setApellidoMaterno('');
            setFechaEvento('');
            setNumEvento('');
            setOpen(!open)
        } else {
            setOpen(!open)
        }
    };

  return (
    <Layout>
        <Dialog
            open={open}
            size='md'
            handler={handleOpen}
            animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
            }}
        >
            <DialogHeader className='relative'>
                <p className='text-xl lg:text-2xl'>Buscar una Mesa de Regalos</p>
                <IoIosCloseCircle className='absolute top-2 right-2 fill-azul cursor-pointer' size={32} onClick={handleOpen} />
            </DialogHeader>
            <DialogBody className='flex flex-col justify-start items-start w-full gap-4 max-h-[60vh] overflow-y-auto pt-1'>
                <div className="w-full flex flex-col lg:flex-row justify-between items-center lg:gap-5 -mb-2">
                    <Radio value="numero" name="buscarPor" color='blue' defaultChecked onChange={(e) => setSelect(e.target.value)} label={<p className='text-sm'>Buscar por el número de evento</p>} />
                    <Radio value="nombre" name="buscarPor" color='blue' onChange={(e) => setSelect(e.target.value)} label={<p className='text-sm'>Buscar por nombre y apellido</p>} />
                </div>
                {
                    select === 'numero' ? (
                        <>
                            <p className='text-sm pt-4'>Encuentra la de mesa de regalos con el número de evento</p>
                            <Input size='lg' label='Numero del Evento' type='text' value={numEvento} onChange={(e) => {
                                let value = e.target.value.trim();
                                if (value === "" || isNaN(value) || value.includes('-')) {
                                    setNumEvento("");
                                } else {
                                    // Ensure the value is a number
                                    value = parseInt(value, 10);
                                    // Convert the value to a number
                                    value = Number(value);
                                    setNumEvento(value);
                                }
                                }}
                                onKeyDown={(e) => {
                                    if(e.code === "Space" || e.code === "Minus") e.preventDefault();
                                    if(e.code === "Enter") {
                                        document.getElementById("buscarPorIdMR").click();
                                    }
                                }}
                            />
                            <button id='buscarPorIdMR' disabled={numEvento === ''} className={`p-3 text-white bg-link text-center w-full rounded-md text-sm lg:text-base transition-all ${numEvento === "" ? "opacity-50" : "opacity-100"}`} onClick={() => window.location.href = "/mesaregalos/" + numEvento}>Buscar una Mesa de Regalos</button>
                        </>
                    )
                    : select === 'nombre' ?
                    (
                        <>
                            <p className='text-sm pb-2'>Busca con el nombre de los festejados</p>
                            <Input size='lg' label='Nombre *' onChange={(e) => {
                                let value = e.target.value.trim();
                                if (value === " " || !isNaN(value)) {
                                    setNombre("");
                                } else {
                                    setNombre(value);
                                }
                            }} value={nombre} />
                            <Input size='lg' label='Apellido Paterno *' onChange={(e) => {
                                let value = e.target.value.trim();
                                if (value === " " || !isNaN(value)) {
                                    setApellidoPaterno("");
                                } else {
                                    setApellidoPaterno(value);
                                }
                            }} value={apellidoPaterno} />
                            <Input size='lg' label='Apellido Materno' onChange={(e) => {
                                let value = e.target.value.trim();
                                if (value === " " || !isNaN(value)) {
                                    setApellidoMaterno("");
                                } else {
                                    setApellidoMaterno(value);
                                }
                            }} value={apellidoMaterno} />
                            <Input size='lg' label='Fecha del Evento *' type='date' onChange={(e) => setFechaEvento(e.target.value)} value={fechaEvento} />
                            <button disabled={
                                nombre === '' || apellidoPaterno === '' || fechaEvento === ''
                            } className={`p-3 text-white bg-link text-center w-full rounded-md text-sm lg:text-base transition-all ${nombre === '' || apellidoPaterno === '' || fechaEvento === '' ? "opacity-50" : "opacity-100"}`} onClick={() => window.location.reload}>Buscar una Mesa de Regalos</button>
                        </>
                    )
                    :
                    null
                }
                
            </DialogBody>
        </Dialog>
        <img src="/images/bannerMDR.png" alt="" className='w-full h-12 object-cover lg:h-auto' />
        <div className="w-full h-[60vh] bg-[url('/images/mesaregalosBanner.png')] bg-cover bg-center flex justify-center items-center">
            <div className="bg-white py-5 px-8 shadow flex flex-col justify-center items-center w-10/12 max-w-lg gap-2">
                <button className='p-3 text-white bg-link text-center w-full rounded-md text-sm lg:text-base' onClick={handleOpen}>Buscar una Mesa de Regalos</button>
                <a href="/nuevamesaregalos" className='p-3 text-white bg-darkGris text-center w-full rounded-md text-sm lg:text-base'>Crear Mesa de Regalos</a>
            </div>
        </div>
        <div className='container mx-auto py-10 lg:py-14 flex flex-col justify-center items-center gap-1 px-4'>
            <h1 className='text-center text-azul font-negro text-2xl lg:text-3xl'>Razones para crear un registro o una mesa de regalos en MV</h1>
            <p className='text-center max-w-3xl text-sm lg:text-base'>Ya sea que celebres una boda, la llegada de un bebé, un cumpleaños u otra ocasión especial, nuestras Mesas de regalos te ayudarán a ti y a tus invitados a encontrar los regalos perfectos.</p>
            <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start gap-10 ">
                <div className="flex flex-col justify-center items-center gap-2 w-full max-w-xs pt-5">
                    <FaShareAltSquare className='fill-azul' size={80} />
                    <h3 className='text-xl font-medio text-center'>Fácil de compartir</h3>
                    <p className='text-center text-sm'>Comparte tu Mesa de regalos con amigos y familiares, para que sepan exactamente qué regalarte.</p>
                </div>
                <div className="flex flex-col justify-center items-center gap-2 w-full max-w-xs pt-5">
                    <FaGifts className='fill-azul' size={80} />
                    <h3 className='text-xl font-medio text-center'>Celebra cualquier ocasión</h3>
                    <p className='text-center text-sm'>Agrega artículos de nuestro catalogo para crear tu Mesa de regalos.</p>
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default MesaRegalosIndex